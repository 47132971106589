<template>
<div>
  
    
 


  

    <section class="bg-primary-3 min-vh-100 jarallax d-flex align-items-center text-light" data-jarallax data-speed="0.7">
      <img src="assets/portalBackground.jpg" alt="Image" class="jarallax-img">
      <div class="container">
       <transition
       mode="in-out"
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="animate__animated "
       >
        <div class="row text-center justify-content-between extendVine" style="margin-top: 0px;">
          <div class="col-lg-6 mb-4" style="margin: 0 auto;">
            <img src="assets/maingLogo.png" alt="Image" class="rounded shadow-lg">
          </div>
            <div class="col-lg-7 col-xl-8" style="margin: 0 auto;">
                <h1 class="h2 mb-3 text-uppercase" style="letter-spacing: 0.255rem;">Associate Portal</h1>
                <form class="py-2" style="padding: 0px 40px;" action="#" @submit.prevent="logStateIn">
                  <div class="input-group input-group-lg mb-3">
                    
                    
                    <input v-model="username"  type="input" class="form-control text-center m-2 loginInputs" placeholder="username" aria-label="Search" aria-describedby="basic-addon-1" style="border: 0px solid white;border-radius:38px">
                    <input v-model="password"  type="password" class="form-control text-center m-2 loginInputs" placeholder="password" aria-label="Search" aria-describedby="basic-addon-1" style="border: 0px solid white;border-radius:38px">
                    
                  </div>
                
                <div style="position:relative;margin-bottom:10px;" v-if="loading">
                  <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                  </div>
                  
                </div>
                <div style="position:relative;margin-bottom:10px;" v-if="serverError">
                  <div class="server-error">{{ serverError }}</div>
                </div>

                <button class="btn btn-primary" style="background-color:black; border-radius: 27px;border: 0px solid white;margin-top: 14px;" type="submit">
              
                  <div class="px-6 py-1">Login</div>
              
                </button>
                </form>
                
                
                
                </div> <!-- end col 7 wrappng everything -->
                
        </div> <!-- end row wrapping everyting -->
        </transition>
      
              
        </div>

        <!--
        <div class="vineFooter" style="position: absolute;height: 91px;width: 100%;bottom: 0px;display: flex;justify-content: center;align-content: center;">
          <div class="vineFooterInner">
            <a href="http://www.facebook.com/coolvinesnewark">
            <img class="p-2" src="assets/facebookIcon.png" style="height:4.8rem;width:auto"></a>
            <a href="http://www.instagram.com/coolvineshoboken">
            <img class="p-2" src="assets/instagramIcon.png"style="height:4.8rem;width:auto"></a>
          </div>
        </div>  -->
      
    </section>
    
</div>

  
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      query: '',
      searchResultsVisible: false,
      loading: false,
      serverError: '',
      
    }
  },
  methods: {
    logStateIn() {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.username)) {
        console.log('first');
      } else {
        console.log('something else');
      }
      this.loading = true
      this.serverError = '';
        this.$store.dispatch('retrieveToken', {
          email: this.username,
          password: this.password
        })
          .then(response => {
            this.loading = false
            this.$router.push({ name: 'portal' });
          })
          .catch(error => {
            this.loading = false;
            this.serverError = error.response.data.errors.email[0];
            console.log(error.response.data.errors.email[0]);
            this.password = '';
            
          });
      },
  }
  
}
</script>

<style>
      html {
        scroll-behavior: smooth;
        overflow: hidden;
      }
      .navbar-light .navbar-nav .nav-link {
        color: rgba(73,80,87,.5);
        font-weight: 200;
        font-size: 20px; 
      }
     
      .extendVine {
        -webkit-transition: max-height 0.8s;
        -moz-transition: max-height 0.8s;
        transition: max-height 0.8s;
      }
      .searchScroll::-webkit-scrollbar {display:none;}
      .searchScroll {
        -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;
      }
      .fixRouterLink {
        font-weight: 400;

      }
      

      .slideVines-enter-active {
        animation: bounce-in 2s;
      }     
      .slideVines-leave-active {
        animation: bounce-in 0.5s reverse;
      }
      .bounce-transition {
  display: inline-block; /* otherwise scale animation won't work */
}
.bounce-enter {
  animation: bounce-in .5s;
}
.bounce-leave {
  animation: bounce-out .5s;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes bounce-out {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(0);
  }
}
    </style>
