<template>
<div>

    <section class="bg-primary-3 min-vh-100 jarallax d-flex align-items-center text-light" data-jarallax data-speed="0.7">
      <img src="assets/mainBackground.jpg" alt="Image" class="jarallax-img">
      <div class="container">
       <transition
       mode="in-out"
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="animate__animated "
       >
        <div class="row text-center justify-content-between extendVine" style="margin-top: 0px;">
          <div class="col-lg-6 mb-4" style="margin: 0 auto;">
            <img src="assets/maingLogo.png" alt="Image" class="rounded shadow-lg">
          </div>
            <div class="col-lg-8 col-xl-8" style="margin: 0 auto;">
                <h1 class="h2 mb-3 text-uppercase" style="letter-spacing: 0.255rem;">Bottle Finder</h1>
                <div class="py-2" style="padding: 0px 40px;">
                  <div class="input-group input-group-lg mb-3">
                    <div class="input-group-prepend" style="width: 100px;background-color: white; border-radius: 27px 0 0 27px;">
                      <span class="input-group-text" id="basic-addon-1" style="border-radius: 27px 0 0 27px;border-right: 0px solid white;border-bottom: 0px solid white;">
                        <svg width="24px" height="24px" style="margin-left: 4px;" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="injected-svg icon" data-src="assets/img/icons/theme/general/search.svg">
                            <title>Search</title>
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                                <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fill-rule="nonzero"></path>
                            </g>
                        </svg>
                      </span>
                    
                    </div>
                    
                    <input v-model="query" 
                       type="search" 
                       @keyup="searchQuery"
                       class="form-control text-center" 
                       placeholder="Search Here" 
                       aria-label="Search" 
                       aria-describedby="basic-addon-1" style="border: 0px solid white;font-weight:300;"
                       
                       >
                    <div class="input-group-append">
                        <span class="input-group-text border-0 py-0 pl-1 pr-3" style="border-radius: 0 27px 27px 0;width: 100px;">
      
                          <!-- Text -->
                          <span class="h6 d-none d-md-block mb-0 mr-5" style="color: #c4cacf;border-radius: 0 27px 27px 0;margin-left: -28px;z-index: 4;">
                            <div v-if="query.length > 1 " style="margin-left:16px">
                            {{displayResultsCount}} Results
                            </div>
                          </span>
  
      
                        </span>
                      </div>
                  </div>
                </div>
                <transition
                  mode="in-out"
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="animate__animated "
                >
                <div v-if="query.length > 0">
                <div class="searchScroll" style="; top: 0px;margin-top: -36px; max-height: 45vh;overflow-y: scroll;overflow-x: hidden;">
                  <div class="row justify-content-center" style="margin-top: 0px;padding-top: 0px;">
                    
                    <search-item v-for="(i, index) in displayResults" :key="index" :index='i' :queryItem="i"></search-item>
                    
                    
                    
                </div>
                

                </div>
                <!-- start search results -->
                <div style="">
                  <div class="row justify-content-center" style="margin-top: 0px;padding-top: 0px;">
                  <div class="col-md-10" style="">
                    <div aria-labelledby="demosMegaMenu" style="max-width: 900px;border-top: 1px solid red;border-left: 1px solid red;border-right: 1px solid red;height: 34px;background-color: red; border-radius: 0px 0px 14px 14px;display:flex;justify-content: center;align-items:center" class="w-100 hs-mega-menu-desktop-lg hs-position-right animated p-2">
                      <i class="fa fa-caret-down" style="font-size:1.75rem;margin-left:-2px"></i>
                    </div>
                  </div>
                  </div>
                </div>
                </div>
                </transition>
                
                </div> <!-- end col 7 wrappng everything -->
                
        </div> <!-- end row wrapping everyting -->
        </transition>
      
              
        </div>

        <!--
        <div class="vineFooter" style="position: absolute;height: 91px;width: 100%;bottom: 0px;display: flex;justify-content: center;align-content: center;">
          <div class="vineFooterInner">
            <a href="http://www.facebook.com/coolvinesnewark">
            <img class="p-2" src="assets/facebookIcon.png" style="height:4.8rem;width:auto"></a>
            <a href="http://www.instagram.com/coolvineshoboken">
            <img class="p-2" src="assets/instagramIcon.png"style="height:4.8rem;width:auto"></a>
          </div>
        </div>  -->
      
    </section>
  
</div>

  
</template>

<script>
import searchItem from "./comps/searchItem";
import cartItem from "./comps/cartItem";
import _ from "lodash";
import axios from 'axios';



export default {
  components: {
    searchItem,
    cartItem,
  },
  async created() {
    
  },
  computed: {
    shelf() {
      NProgress.done();
      return this.$store.shelf;
    },
    
  },
  data() {
    return {
      querySearch: '',
      query: '',
      searchResultsVisible: false,
      displayResults: '',
      displayResultsCount: '',
      
    }
  },
  methods: {
    searchQuery:_.debounce(function() {
        if(this.$store.getters.loggedIn) {
          if(localStorage.getItem('access_token_local') == 'JC') {
            axios.get('api/searchjc?query=' + this.query).then(response => {
            console.log(response.data.SearchResult.data);
            console.log(response.data.Count);
            this.displayResultsCount = response.data.Count;
            this.displayResults = response.data.SearchResult.data;
          });
          } else if(localStorage.getItem('access_token_local') == 'NW') {
            axios.get('api/searchnw?query=' + this.query).then(response => {
            console.log(response.data.SearchResult.data);
            console.log(response.data.Count);
            this.displayResultsCount = response.data.Count;
            this.displayResults = response.data.SearchResult.data;
          });
          } else if(localStorage.getItem('access_token_local') == 'HB') {
            axios.get('api/searchhb?query=' + this.query).then(response => {
            console.log(response.data.SearchResult.data);
            console.log(response.data.Count);
            this.displayResultsCount = response.data.Count;
            this.displayResults = response.data.SearchResult.data;
          });
          } else {
            axios.get('api/search?query=' + this.query).then(response => {
            console.log(response.data.SearchResult.data);
            console.log(response.data.Count);
            this.displayResultsCount = response.data.Count;
            this.displayResults = response.data.SearchResult.data;
          });
          }
        } else {
          axios.get('api/search?query=' + this.query).then(response => {
          console.log(response.data.SearchResult.data);
          console.log(response.data.Count);
          this.displayResultsCount = response.data.Count;
          this.displayResults = response.data.SearchResult.data;
        });
        }
        
    },200),
    logOutState() {
      this.$store.dispatch('destroyToken')
      .then(response => {
        this.$router.push({ name: 'Home' })
      })
    },
  },
  
  
}
</script>

<style>
      html {
        scroll-behavior: smooth;
        overflow: hidden;
      }
      .navbar-light .navbar-nav .nav-link {
        color: rgba(73,80,87,.5);
        font-weight: 200;
        font-size: 20px; 
      }
      .extendVine {
        -webkit-transition: max-height 0.8s;
        -moz-transition: max-height 0.8s;
        transition: max-height 0.8s;
      }
      
      .searchScroll::-webkit-scrollbar {display:none;}
      .searchScroll {
        -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;
      }
      .dropdown-menu-hidescroll::-webkit-scrollbar {display:none;}
      .dropdown-menu-hidescroll {
        -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;
      }
      .fixRouterLink {
        font-weight: 400;

      }
      .fa-plus-circle:hover {
        color:red;
      }

      .shelfLink {
        height: 45px;
        width: 100%;
        background-color: rgb(255, 255, 255);
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #8080805e;
        letter-spacing: 0.18rem;
        font-weight: 400;
        font-size: 0.96rem;
      }
      .fixShelfLink {
        font-weight: 400;
        color: gray;
      }
      .fixShelfLink:hover {
        color: #642b85;
      }

      .slideVines-enter-active {
        animation: bounce-in 2s;
      }     
      .slideVines-leave-active {
        animation: bounce-in 0.5s reverse;
      }
      .bounce-transition {
  display: inline-block; /* otherwise scale animation won't work */
}
.bounce-enter {
  animation: bounce-in .5s;
}
.bounce-leave {
  animation: bounce-out .5s;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes bounce-out {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(0);
  }
}
    </style>
