<template>
<div  style="position:relative;top:0px;margin-top:0px;border:1px solid rgba(0,0,0,0.2);width:270px;height:420px;background-image: url('https://bfapi.coolvines.com/img/wine/ST_wine_blank2final.png');background-size:cover;">

          <!-- Color Banner -->
         <!-- <div  :class="[
             returnRed() ? 'colorBannerRed' : '',
             returnWhite() ? 'colorBannerWhite' : '',
             returnRose() ? 'colorBannerRose' : '',
             returnOrange() ? 'colorBannerOrange' : '',
         ]" style="position:absolute;top:0.82rem;right:0px;height:78px;width:257px;">
         </div> -->
         <div  :class="{
            'colorBannerRed' : (previewValue.wine_type.toLowerCase()).includes('red'),
            'colorBannerWhite' : (previewValue.wine_type.toLowerCase()).includes('white'),
            'colorBannerRose' : (previewValue.wine_type.toLowerCase()).includes('rose'),
            'colorBannerOrange' : returnOther(),
         }" style="position:absolute;top:0.82rem;right:0px;height:78px;width:257px;">
         </div>
         <!-- Main Title -->          
        <div style="position:absolute;max-height:48px;width:195px;overflow:hidden;">
                    <h3 style="color:black;font-family:'Gotham-Bold';font-size:15pt;padding-top:9px;padding-left:4px;text-transform: uppercase;line-height:1.24rem;">
                   
                    {{previewValue.title.split('-')[0]}}
                    </h3>
        </div> 
        <!-- Second Title -->
        <div style="position:absolute;top:52px;height:45px;width:195px;overflow:hidden;">
            <div style="position:relative;left:8px;width:140px;">
                <span style="font-family:'Gotham-Bold';font-size:14pt;line-height:1rem;">
                        
                        {{previewValue.title.split('-')[1]}}
                </span>
            </div>
            
        </div>
        <!-- Grape Logo -->
        <div style="position:absolute;right:0px;height:102px;width:74px;display:flex;">
            <div style="position:absolute;top:15px;right:10px;height:72px;width:60px;">
                <div style="position:absolute;z-index:4;top:8px;left:1px;height:56px;width:56px;background-color:white;border-radius:35px;">
                 
                </div>
                <div :class="{
                    'bodyFull' : (previewValue.body.toLowerCase()).includes('full'),
                    'bodyMedium' : (previewValue.body.toLowerCase()).includes('medium'),
                    'bodyLight' : (previewValue.body.toLowerCase()).includes('light'),
                    'bodyFortified' : (previewValue.body.toLowerCase()).includes('fortified'),
                    'bodySparkling' : (previewValue.body.toLowerCase()).includes('sparkling'),
                    'bodySweet' : (previewValue.body.toLowerCase()).includes('sweet'),
                }" 
                style="position:absolute;z-index:4;top:8px;left:1px;height:56px;width:56px;">
                 
                </div>
            </div>
        </div>                

        <!-- Grape | Style | Region -->            
        <div style="position:absolute;top:102px;height:55px;width:64px;">
            <span style="position:absolute;top:4px;left:4px;font-family:'Gotham-Medium';font-size:9pt;">STYLE:</span>
            <span style="position:absolute;top:21px;right:5px;font-family:'Gotham-Medium';font-size:9pt">GRAPES:</span>
            <span style="position:absolute;top:37px;left:5px;font-family:'Gotham-Medium';font-size:9pt">REGION:</span>
        </div>
        <!-- Listed Description --> 
        <div style="position:absolute;top:100px;right:0px;height:72px;width:204px;">
            <div style="position:absolute;margin-top:0px;;width:190px;left:10px;max-height:20px;overflow:hidden;">
                <span style="font-family:'Gotham-LightIta';font-weight:200;font-style:italic;font-size:9pt;color:rgba(40,40,40,1);">
                    {{previewValue.style}}, {{previewValue.body}}
                </span>
            </div>
            <div style="position:absolute;top:19px;width:197px;left:10px;max-height:17px;overflow:hidden;">
                <p style="margin-top:3px;font-family:'Gotham-LightIta';font-weight:200;font-style:italic;font-size:9pt;color:rgba(40,40,40,1);">
                    {{previewValue.varietal}}
                </p>
            </div>
            <div style="position:absolute;top:40px;width:190px;left:10px;">
                <p style="font-family:'Gotham-LightIta';font-weight:200;font-style:italic;font-size:9pt;line-height:1rem;;color:rgba(40,40,40,1);">
                {{previewValue.region}}, {{previewValue.country}}
                </p>
            </div>
        </div>
            
        <!-- Farm Practice -->
        <div style="position:absolute;top:160px;width:64px;height:183px;">
            <div style="position:relative;height:100%;width:100%;margin-top:2px;">
               
                <!-- Example Farm Practice -->  
                <div v-if="previewValue.farmpractice_sustainable" style="position:relative;width:100%;"> 
                    <div style='height:44px;width:34px;margin: 1px auto;padding:2px;background-image:url(https://bfapi.coolvines.com/img/farm/sustainable.png);background-size:contain;background-position:center center;background-repeat: no-repeat;'>
                    </div> 
                </div>
                <div v-if="previewValue.farmpractice_biodynamic" style="position:relative;width:100%;"> 
                    <div style='height:44px;width:34px;margin: 1px auto;padding:2px;background-image:url(https://bfapi.coolvines.com/img/farm/bio.png);background-size:contain;background-position:center center;background-repeat: no-repeat;'>
                    </div> 
                </div>
                <div v-if="previewValue.farmpractice_natural" style="position:relative;width:100%;"> 
                    <div style='height:44px;width:34px;margin: 1px auto;padding:2px;background-image:url(https://bfapi.coolvines.com/img/farm/natural.png);background-size:contain;background-position:center center;background-repeat: no-repeat;'>
                    </div> 
                </div>
                <div v-if="previewValue.farmpractice_organic" style="position:relative;width:100%;"> 
                    <div style='height:44px;width:34px;margin: 1px auto;padding:2px;background-image:url(https://bfapi.coolvines.com/img/farm/organic.png);background-size:contain;background-position:center center;background-repeat: no-repeat;'>
                    </div> 
                </div>
                <div v-if="previewValue.farmpractice_limited" style="position:relative;width:100%;"> 
                    <div style='height:44px;width:34px;margin: 1px auto;padding:2px;background-image:url(https://bfapi.coolvines.com/img/farm/limited.png);background-size:contain;background-position:center center;background-repeat: no-repeat;'>
                    </div> 
                </div>
                <div v-if="previewValue.farmpractice_kosher" style="position:relative;width:100%;"> 
                    <div style='height:44px;width:34px;margin: 1px auto;padding:2px;background-image:url(https://bfapi.coolvines.com/img/farm/kosher.png);background-size:contain;background-position:center center;background-repeat: no-repeat;'>
                    </div> 
                </div>
                    
             
            </div>
        </div>
        
        <!-- Pricing -->
        <div style="position:absolute;top:344px;width:67px;height:24px;display:flex;text-align:center;justify-content:center;">
            
            <h3 v-if="previewValue.price" style="color:black;font-size:17pt;margin-top:-1px;font-family:'Gotham-Medium';font-weight:300;margin:0 auto;">
            
                ${{previewValue.price.split('.')[0]}}
            </h3>
           
        </div>
        <!-- QR --> 
        <div style="position:absolute;bottom:5px;width:64px;height:40px;">
            <div style="position:relative;height:100%;width:100%;display:flex;justify-content:center;">
                <div style="position:absolte;height:40px;width:40px;margin:0 auto;">
                        
                      
               
                           
                </div>
            </div>
        </div>

        <!-- Description --> 
        <div style="position:absolute;top:190px;right:0px;width:204px;height:241px;">
            <div style="position:absolute;top:5px;width:186px;left:12px;margin-top:-9px;">
                <p style="font-family:'Gotham-Book';font-size:10pt;line-height:1rem;">
                     
                    {{previewValue.shelf_description}}
                
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'preview-wine',
    props: {
        previewValue: {},
    },
    methods: {
        returnOther() {
            if((this.previewValue.wine_type.toLowerCase()).includes('red') == false
                && (this.previewValue.wine_type.toLowerCase()).includes('white') == false
                && (this.previewValue.wine_type.toLowerCase()).includes('rose') == false 
                
               ) {
                    console.log('testing value: ');
                    return true;
                } else {
                    console.log('no value');
                    return false;
                }
        },
        returnRed() {
           /*  if((this.previewValue.grape_logo != 0)) {
                if((this.previewValue.grape_logo.split('/')[5]).split('_').includes('red')) {
                    return true;
                }
                
            } else {
                return false;
            } */
            if((this.previewValue.wine_type.toLowerCase()).includes('red')) {
                return true;
            } else { return false}
        },
        returnWhite() {
            /* if((this.previewValue.grape_logo != 0)) {
                if((this.previewValue.grape_logo.split('/')[5]).split('_').includes('white')) {
                    return true;
                }
                
            } else {
                return false;
            } */
            if((this.previewValue.wine_type.toLowerCase()).includes('white')) {
                return true;
            } else { return false}
        },
        returnRose() {
            /* if((this.previewValue.grape_logo != 0)) {
                if((this.previewValue.grape_logo.split('/')[5]).split('_').includes('rose.jpg')) {
                    return true;
                }
                
            } else {
                return false;
            } */
            if((this.previewValue.wine_type.toLowerCase()).includes('rose')) {
                return true;
            } else { return false}
        },
        returnOrange() {
            /* if((this.previewValue.grape_logo) == 0) {
                return true;
                
            } else {
                return false;
            } */
            if((this.previewValue.wine_type.toLowerCase()).includes('orange')) {
                return true;
            } else { return false}
        },
        splitTitle(title) {
            return title;
            // return st[0];
        },
        splitSecondTitle(title) {
            /* var st = title.split(',');
            return st[0]; */
            return title;
        },
    },
    mounted() {
       
    }
}
</script>
<style>



        .bodyFull {
            background-image: url(https://bfapi.coolvines.com/img/body/full.png);background-size:contain;background-position:center center;background-repeat: no-repeat;z-index:44;
        }
        .bodyMedium {
            background-image: url(https://bfapi.coolvines.com/img/body/med.png);background-size:contain;background-position:center center;background-repeat: no-repeat;z-index:44;
        }
        .bodyLight {
            background-image: url(https://bfapi.coolvines.com/img/body/light.png);background-size:contain;background-position:center center;background-repeat: no-repeat;z-index:44;
        }
        .bodyFortified {
            background-image: url(https://bfapi.coolvines.com/img/body/fort.png);background-size:contain;background-position:center center;background-repeat: no-repeat;z-index:44;
        }
        .bodySparkling {
            background-image: url(https://bfapi.coolvines.com/img/body/bubbles.png);background-size:contain;background-position:center center;background-repeat: no-repeat;z-index:44;
        }
        .bodySweet {
            background-image: url(https://bfapi.coolvines.com/img/body/sweet.png);background-size:contain;background-position:center center;background-repeat: no-repeat;z-index:44;
        }

        .colorBannerRed { background-color:#BA7CAD; }
        .colorBannerRose { background-color:#F277A1; }
        .colorBannerWhite { background-color:#E9D947; }
        .colorBannerOrange { background-color:#F47A5A; }
</style>