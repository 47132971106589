var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
                    'spiritsMezcal2' : (_vm.previewValue.style.toLowerCase()).includes('mezcal'),
                    'spiritsTequila2' : (_vm.previewValue.style.toLowerCase()).includes('tequila'),
                    'spiritsGin2' : (_vm.previewValue.style.toLowerCase()).includes('gin'),
                    'spiritsVodka2' : (_vm.previewValue.style.toLowerCase()).includes('Vodka'),
                    'spiritsBourbon2' : (_vm.previewValue.style.toLowerCase()).includes('bourbon'),
                    'spiritsLiqueur2' : (_vm.previewValue.style.toLowerCase()).includes('liqueur'),
                    'spiritsBourbon2' : (_vm.previewValue.style.toLowerCase()).includes('bourbon'),
                    'spiritsRum2' : (_vm.previewValue.style.toLowerCase()).includes('rum'),
                    'spiritsIrish2' : (_vm.previewValue.style.toLowerCase()).includes('irish'),
                    'spiritsScotch2' : (_vm.previewValue.style.toLowerCase()).includes('scotch'),
                    'spiritsBrandy2' : (_vm.previewValue.style.toLowerCase()).includes('brandy'),
                    'spiritsVermouth2' : (_vm.previewValue.style.toLowerCase()).includes('vermouth'),
                    'spiritsSake2' : (_vm.previewValue.style.toLowerCase()).includes('sake'),
                    'spiritsRye2' : (_vm.previewValue.style.toLowerCase()).includes('rye'),
                    'spiritsMixers2' : (_vm.previewValue.style.toLowerCase()).includes('mixers'),
                    'spiritsDigestif2' : (_vm.previewValue.style.toLowerCase()).includes('digestif'),
                    'spiritsBitters2' : (_vm.previewValue.style.toLowerCase()).includes('bitters'),
                    'spiritsAperitif2' : (_vm.previewValue.style.toLowerCase()).includes('aperitif'),
                    'spiritsAmericanWhiskey2' : (_vm.previewValue.style.toLowerCase()).includes('american whiskey'),
                    'spiritsWorldWhiskey2' : (_vm.previewValue.style.toLowerCase()).includes('world whiskey'),
                    
                },staticStyle:{"position":"relative","top":"0px","left":"0px","right":"0px","width":"420px","height":"270px","padding-top":"16Sspx","background-size":"cover","border":"1px solid rgba(0,0,0,0.2)"}},[_c('div',{staticStyle:{"position":"absolute","top":"90px","right":"29px","height":"45px","width":"280px"}},[_c('h3',{staticStyle:{"color":"black","font-family":"'Gotham-Bold'","font-size":"14pt","padding-top":"7px","padding-left":"8px","text-transform":"uppercase","line-height":"1.24rem"}},[_vm._v(" "+_vm._s(_vm.previewValue.title)+" ")])]),_c('div',{staticStyle:{"position":"absolute","top":"138px","right":"28px","max-height":"235px","width":"280px","overflow":"hidden"}},[_c('div',{staticStyle:{"position":"relative","margin-top":"0px","margin-left":"7px","margin-right":"7px"}},[_c('p',{staticStyle:{"font-family":"'Gotham-Book'","font-weight":"300","font-size":"10pt","line-height":"1rem"}},[_vm._v(" "+_vm._s(_vm.trimDescription(_vm.previewValue.shelf_description))+" ")])])]),_c('div',{staticStyle:{"position":"absolute","top":"85px","left":"8px","height":"61px","width":"62px"}},[(_vm.previewValue.farmpractice_organic)?_c('div',{staticStyle:{"position":"absolute","top":"12px","left":"12px","height":"37px","width":"38px","border-radius":"24px","margin":"1px auto","padding":"2px","background-image":"url(https://bfapi.coolvines.com/img/farm/organic.png)","background-size":"contain","background-position":"center center","background-repeat":"no-repeat"}}):_vm._e()]),_c('div',{staticStyle:{"position":"absolute","bottom":"0px","left":"0px","height":"48px","width":"107px"}},[_c('h3',{staticStyle:{"color":"black","font-size":"24pt","padding-left":"8px","padding-top":"1px","font-family":"'Gotham-Medium'","font-weight":"300","margin":"0 auto"}},[_vm._v(" $"+_vm._s(_vm.previewValue.price.split('.')[0])+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }