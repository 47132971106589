<template>
<div
         
        style="position:relative;top:0px;left:0px;right:0px;width:402px;height:120px;;background-size:cover;border: 1px solid rgba(0,0,0,0.2)">
        <!-- Title -->
        <div style="position:absolute;top:4px;left:95px;height:50px;width:255px;">
            <h4 style="color:black;font-family:'Gotham-Bold';margin-top:7px;font-size:14pt;padding-top:0px;padding-left:6px;text-transform: uppercase;line-height:1.32rem;letter-spacing:0.01rem;">
                {{previewValue.title.split('-')[0]}}
            </h4>
        </div>
        <div style="position:absolute;left:95px;top:60px;height:55px;width:195px;overflow:hidden;">
            <div style="position:relative;left:8px;max-width:170px;">
                <span style="color:black;font-family:'Gotham-MediumIta';line-height:0.74rem;font-style:italic;font-size:14pt;">
                        {{previewValue.varietal.split('/')[0].split(',')[0]}}
                </span>
            </div>
            
        </div>
        <div :class="{
                   
                    'colorBannerRed' : (previewValue.wine_type.toLowerCase()).includes('red'),
                    'colorBannerWhite' : (previewValue.wine_type.toLowerCase()).includes('white'),
                    'colorBannerRose' : (previewValue.wine_type.toLowerCase()).includes('rose'),
                    'colorBannerOrange' : returnOther(),
                }" style="position:absolute;left:3px;top:3px;height:112px;width:82px;">


            <div style="position:absolute;top:26px;left:9px;height:64px;width:64px;border-radius:34px;background-color:white;">

            </div>
            <!-- <div :class="{
                    'bodyFull' : previewValue.body.split(/\s+/).includes('Full'),
                    'bodyMedium' : previewValue.body.split(/\s+/).includes('Medium'),
                    'bodyLight' : previewValue.body.split(/\s+/).includes('Light'),
                    'bodyFortified' : previewValue.body.split(/\s+/).includes('Fortified'),
                    'bodySparkling' : previewValue.body.split(/\s+/).includes('Sparkling'),
                    'bodySweet' : previewValue.body.split(/\s+/).includes('Sweet'),
                }" style="position:absolute;top:17px;left:1px;height:80px;width:80px;background-size:contain;background-position:center center;background-repeat: no-repeat;z-index:44;">

            </div> -->
            <div :class="{
                    'bodyFull' : (previewValue.body.toLowerCase()).includes('full'),
                    'bodyMedium' : (previewValue.body.toLowerCase()).includes('medium'),
                    'bodyLight' : (previewValue.body.toLowerCase()).includes('light'),
                    'bodyFortified' : (previewValue.body.toLowerCase()).includes('fortified'),
                    'bodySparkling' : (previewValue.body.toLowerCase()).includes('sparkling'),
                    'bodySweet' : (previewValue.body.toLowerCase()).includes('sweet'),
                }" style="position:absolute;top:17px;left:1px;height:80px;width:80px;background-size:contain;background-position:center center;background-repeat: no-repeat;z-index:44;">

            </div>
        </div>
        
        <!-- Price -->
        <div style="position:absolute;bottom:10px;right:0px;height:34px;width:75px;overflow:hidden;">
            <h3 style="color:black;font-size:24pt;padding-left:8px;padding-top:1px;font-family:'Gotham-Medium';font-weight:300;margin:0 auto;">
                
               ${{previewValue.price.split('.')[0]}}
            </h3>
        </div>
        <!-- Farming Practice -->
      
        
    </div>
</template>
<script>
export default {
    name: 'preview-wine2',
    props: {
        previewValue: {},
    },
    created() {
        console.log('created beer');
    },
    mounted() {
        console.log('mounted beer');
        
    },
    methods: {
        returnOther() {
            if((this.previewValue.wine_type.toLowerCase()).includes('red') == false
                && (this.previewValue.wine_type.toLowerCase()).includes('white') == false
                && (this.previewValue.wine_type.toLowerCase()).includes('rose') == false 
               ) {
                    console.log('testing value: ');
                    return true;
                } else {
                    console.log('no value');
                    return false;
                }
        },
        returnRed() {
           /*  if((this.previewValue.grape_logo != 0)) {
                if((this.previewValue.grape_logo.split('/')[5]).split('_').includes('red')) {
                    return true;
                }
                
            } else {
                return false;
            } */
            if((this.previewValue.wine_type.toLowerCase()).includes('red')) {
                return true;
            } else { return false}
        },
        returnWhite() {
            /* if((this.previewValue.grape_logo != 0)) {
                if((this.previewValue.grape_logo.split('/')[5]).split('_').includes('white')) {
                    return true;
                }
                
            } else {
                return false;
            } */
            if((this.previewValue.wine_type.toLowerCase()).includes('white')) {
                return true;
            } else { return false}
        },
        returnRose() {
            /* if((this.previewValue.grape_logo != 0)) {
                if((this.previewValue.grape_logo.split('/')[5]).split('_').includes('rose.jpg')) {
                    return true;
                }
                
            } else {
                return false;
            } */
            if((this.previewValue.wine_type.toLowerCase()).includes('rose')) {
                return true;
            } else { return false}
        },
        returnOrange() {
            /* if((this.previewValue.grape_logo) == 0) {
                return true;
                
            } else {
                return false;
            } */
            if((this.previewValue.wine_type.toLowerCase()).includes('orange')) {
                return true;
            } else { return false}
        },
        splitTitle(title) {
            var st = title.split('-');
            return st[0];
        },
        splitSecondTitle(title) {
            var st = title.split(',');
            return st[0];
        },
    },
    
}
</script>
<style>



        .bodyFull {
            background-image: url(https://bfapi.coolvines.com/img/body/full.png);background-size:contain;background-position:center center;background-repeat: no-repeat;z-index:44;
        }
        .bodyMedium {
            background-image: url(https://bfapi.coolvines.com/img/body/med.png);background-size:contain;background-position:center center;background-repeat: no-repeat;z-index:44;
        }
        .bodyLight {
            background-image: url(https://bfapi.coolvines.com/img/body/light.png);background-size:contain;background-position:center center;background-repeat: no-repeat;z-index:44;
        }
        .bodyFortified {
            background-image: url(https://bfapi.coolvines.com/img/body/fort.png);background-size:contain;background-position:center center;background-repeat: no-repeat;z-index:44;
        }
        .bodySparkling {
            background-image: url(https://bfapi.coolvines.com/img/body/bubbles.png);background-size:contain;background-position:center center;background-repeat: no-repeat;z-index:44;
        }
        .bodySweet {
            background-image: url(https://bfapi.coolvines.com/img/body/sweet.png);background-size:contain;background-position:center center;background-repeat: no-repeat;z-index:44;
        }

        .colorBannerRed { background-color:#BA7CAD; }
        .colorBannerRose { background-color:#F277A1; }
        .colorBannerWhite { background-color:#E9D947; }
        .colorBannerOrange { background-color:#F47A5A; }
</style>