<template>
    <div class="col-md-10 " style="border-bottom: 0px solid rgb(95, 95, 95);">
                      
                      <div aria-labelledby="demosMegaMenu" style="max-width: 900px;background-color: white;border-top: 1px solid #e6e6e6;border-left: 1px solid #e6e6e6;border-right: 1px solid #e6e6e6;" class="w-100 hs-mega-menu-desktop-lg hs-position-right animated p-2">
                          <router-link
                            
                            :to="{
                              name: 'test', 
                              params: {slug: queryItem.slug},
                              }"  
                              class="fixRouterLink"
                            > 
                          <div class="row no-gutters">
                            <div class="col-8" style="display: flex;justify-content: center;align-items: center;">
                              <div class="row">
                                <div class="col-2"></div>
                                <div class="col-10 text-center" style="margin-top: ;padding-bottom: 4px;margin-top: -4px;">
                                  <span style="font-size: 1.4rem;margin-left: ;color: rgb(83, 83, 83);margin-top: ;padding-top: -10px;">
                                    {{ queryItem.title }}</span>
                                </div>
                                <div class="col-2"></div>
                                
                                <div class="col-4 text-center topScore" v-html="queryItem.topscore">
                                  
                                                         
                                </div>
                                <div class="col-6 text-left">
                                  <span style="color: #7e7e7e;margin-left:-10px">{{ queryItem.varietal }}</span>
                                </div>
                              </div>   
                              <div class="row">
                                
                              </div>          
                            </div>
      
                            <div class="col-4">
                              <a href="#" class="py-1">
                                <div class="position-relative" style="display: flex;justify-content: center;align-items: center;">
                                  <img v-if="queryItem.img_src" :src="queryItem.img_src"  style="border-radius: 0px;height: 80px;width: auto;" class="img-fluid">
                                  <div v-else style="height:80px;width:auto;object-fit:contain;display:flex;align-items:center;justify-content:center;" class="rounded mb-4">
                                    <i class="uil uil-capture" style="font-size:4rem;color:rgba(0,0,0,0.1)"></i>
                                  </div>
                                </div>
                                
                              </a>
                              
                              
                            </div> <!-- end col 4-->
                            
                          </div>
                           </router-link>
                           <div class="hoverAddToShelf" v-if="this.$store.getters.loggedIn" style="position:absolute;height:30px;width:30px;top:40px;right:28px;z-index:44;">
                               <button @click="addToShelf" class="styleAddToShelf">
                                <i class="fa fa-plus-circle" style="color:green;font-size:1.15rem" aria-hidden="true"></i>

                                </button>
                           </div>
                           <div class="hoverAddToShelf" v-if="this.$store.getters.loggedIn" style="position:absolute;height:30px;width:30px;top:40px;left:28px;z-index:44;">
                               <button @click="showInfo" class="styleAddToShelf">
                                <i class="uil uil-info-circle" style="font-size:1.4rem;color:rgba(0,0,0,0.4)"></i>

                                </button>
                           </div>
                        </div>
                        
                        
                    </div>
</template>
<script>

export default {
    name: 'search-item',
    props: {
        index: {},
        queryItem: {},
    },
    methods: {
        
        addToShelf() {
            console.log("...adding " + this.index);
            this.$store.commit('addToShelf', this.queryItem);
            this.$toasted.success('Added!');
        },
        htmlEncode ( html )
        {
            html = $.trim(html);
            return html.replace(/[&"'\<\>]/g, function(c) 
            {
                  switch (c) 
                  {
                      case "&":
                        return "&amp;";
                      case "'":
                        return "&#39;";
                      case '"':
                        return "&quot;";
                      case "<":
                        return "&lt;";
                      default:
                        return "&gt;";
                  }
            });
        },
        showInfo() {
          alert('Will display remark in a similar modal window but styled appropriately/centered');
        }
    }
}
</script>

<style scoped>
.hoverAddToShelf:hover {
    cursor: pointer;
    scale: 1.2rem;
}
.styleAddToShelf {
  outline: 0ch;
  border: 0px solid white;
  background-color: white;

}


.topScore >>> img {
  padding-left: 1px;
  padding-right: 1px;
}
</style>
