<template>
<div>
  <div class="navbar-container " style="margin-right:0px">
      <nav class="navbar navbar-expand-lg navbar-light justify-content-end" style="" data-overlay>
        
        <div class="container-fluid mt-2">
          <div class="col-3" style="">
            <router-link v-if="(this.$route.name !== 'Home' || this.$route.name == 'shelf' || this.$route.name == 'login' || this.$route.name == 'portal' )" to="/" style="color:white" class="fixLink">
            <div class="btn btn-primary" style="background-color:#642b85; border-radius: 100%;width: 50px;height: 50px;border: 0px solid white;display: flex;justify-content: center;align-items: center;margin-top: 14px;">             
                <div class="px-0 py-0">
                  <i class="fa fa-caret-left" style="font-size:1.75rem;margin-left:-2px"></i>
                </div>
            </div>
            </router-link>
          </div>
          <div class="col-1 text-center" style="">
            <img v-if="(this.$route.name == '')" src="assets/maingLogo.png" alt="Image" class="rounded shadow-lg" style="height: 65px;width: auto;">
          </div>
          <div class="col-8 text-right" style="">

            <button v-if="loggedIn" class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color:#642b85; border-radius: 27px;border: 0px solid white;margin-top: 14px;margin-right:24px">
              
              <div class="px-3 py-1" style="position:relative;">Shelf Talkers
                  <div class="cartUp" v-if="this.$store.state.shelf" style="position: absolute;right: 0px;margin-right: 8px;font-weight: 300;top: 1px;">
                    {{this.$store.state.shelf.length}}
                  </div>
                  
                  <div v-if="this.$store.state.shelf" class="dropdown-menu " aria-labelledby="dropdownMenuButton" style="min-width:450px;max-width:450px;margin-left:-175px;max-height:545px;overflow:hidden;box-shadow: 0 0.125rem 0.825rem rgba(0, 0, 0, 0.23);border-radius: 18px 45px;">
                    
                    <div class="row dropdown-menu-hidescroll" style="margin-top:-12px;max-height:506px;overflow-y:scroll">
                        <cart-item v-for="(item, index) in this.$store.state.shelf" :key="item.id" :index="index" :item="item"></cart-item>
                       
                    </div>
                    <div class="shelfLink">
                        <router-link :to="{ name: 'shelf' }" class="fixShelfLink">
                        <p style="margin-top:px">View Shelf <i class="fas fa-arrow-right" style="font-size: 0.75rem;"></i>
                        </p>
                        </router-link>
                    </div>
                   
                  </div><!-- end dropdown cart showing listed item  ------------->
              </div>  <!-- end inner wrapper for shelf talkers ---------------->
              
            </button>  <!-- end button shelf talkers --------------->
            
           
            
            <router-link :to="{ name: 'login' }">
              <div v-if="!loggedIn" class="btn btn-primary" style="background-color:#642b85; border-radius: 27px;border: 0px solid white;margin-top: 14px;">
                
                <div class="px-3 py-1">Login</div>
                
              </div>
            </router-link>
            <router-link :to="{ name: 'portal' }">
              <button v-if="loggedIn" class="btn btn-primary" style="background-color:#642b85; border-radius: 27px;border: 0px solid white;margin-top: 14px;margin-right:24px;">
                
                <div class="px-3 py-1">Associate Portal</div>
                
              </button>
            </router-link>
            
            <button v-if="loggedIn" @click="logOutState" class="btn btn-primary" style="background-color:#642b85; border-radius: 27px;border: 0px solid white;margin-top: 14px;margin-right:24px;">
              
              <div class="px-3 py-1">Logout</div>
              
            </button>
              
              
          </div>

        </div>
          
         
          
            
              
            
        
      </nav>
    </div>
  <router-view></router-view>
</div>

  
</template>
<script>
import axios from 'axios';
axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://bfapi.coolvines.com';
//axios.defaults.baseURL = 'https://shelftalkerapi.merginggrowth.com';
//axios.defaults.baseURL = 'http://shelftalkerapi.testmachine.test';
import CartItem from './views/comps/cartItem'
export default {
  components: {
    CartItem,
  },
  created() {
    
      axios.get('/sanctum/csrf-cookie').then(response => {
          
        });
        this.$store.dispatch('getProducts')
          .then(_ => {

          }).catch(error => {
            console.error(error);
          });
    
  },
  computed: {
       loggedIn() {
          return this.$store.getters.loggedIn
        }
  },

    data() {
      return {
        email: 'shelf@email.com',
        password: 'password',
      }
    },
    methods: {
      logOutState() {
        this.$store.dispatch('destroyToken')
        .then(response => {
          this.$router.push({ name: 'Home' })
        })
      },
      logState() {
        this.$store.dispatch('retrieveToken', {
          email: this.email,
          password: this.password
        })
          .then(response => {
            console.log(response)
          });
      },
      logOut() {
        this.$store.dispatch('destroyToken')
          .then(response => {
            console.log("Logging Out: " + reponse);
            this.$router.push({name: 'Home'})
          })
      },
      login() {
        axios.get('/sanctum/csrf-cookie').then(response => {
          axios.post('/login', {
            
              email: this.email,
              password: this.password,
          
          }).then(response2 => {
            axios.get('/api/user').then(response4 => {
            localStorage.setItem('isLoggedIn', response4.data.email);
            console.log(response4.data.email);
          });
          });
        });
      },
      helloUser() {
        axios.get('api/user').then(response => {
          console.log(response.data.email);
        });
      },
      byeUser() {
        axios.post('/logout').then(response => {
          localStorage.removeItem('isLoggedIn');
          console.log(response);
        });
      }
    
    }
  
}
</script>
