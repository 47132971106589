import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toasted from 'vue-toasted';
import _ from 'lodash'


import './assets/css/theme.min.css'
import './assets/css/fonts.css'


Vue.config.productionTip = false
Vue.use(Toasted, {
  position: 'top-center',
  theme: 'bubble',
  duration: 700,
});

function isLoggedIn() {
  return localStorage.getItem('access_token');
}
function isLoggedIn2() {
  return localStorage.getItem('access_token2');
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isLoggedIn()) {
      next({
        name: 'login',
      })
    } else {
      next()
    }
  } else if(to.matched.some(record => record.meta.requiresAuth2)) {
    if (isLoggedIn2() != 1) {
      next({
        name: 'admin.items',
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
