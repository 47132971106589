<template>
<div>

    <section class="bg-primary-3 min-vh-100 jarallax d-flex align-items-center text-light" data-jarallax data-speed="0.7">
      <img src="assets/portalBackground.jpg" alt="Image" class="jarallax-img">
      <div class="container">
       <transition
       mode="in-out"
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="animate__animated "
       >
        <div class="row text-center justify-content-between extendVine" style="margin-top: 0px;">
          <div class="col-lg-6 mb-4" style="margin: 0 auto;">
            <img src="assets/maingLogo.png" alt="Image" class="rounded shadow-lg">
          </div>
            <div class="col-lg-7 col-xl-8" style="margin: 0 auto;">
                <h1 class="h2 mb-3 text-uppercase" style="letter-spacing: 0.255rem;">Associate Portal</h1>
                <form class="py-2" style="padding: 0px 40px;">
                  
                </form>

                
                
                
            </div> <!-- end col 7 wrappng everything -->
            <div class="col-12" style="margin:0 auto">
                <div class="row justify-content-between">
                      <div class="col-md-4">
                        <router-link :to="{ name: 'shelf' }">
                        <div class="boxPortal"><img src="/assets/Shelftalkers.png" alt="Image" class="rounded shadow-lg"><h3 class="text-light mt-3 text-uppercase" style="margin-bottom:-15px;letter-spacing:0.4rem;font-weight:500">Shelf Talkers</h3>
                        </div>
                        </router-link>
                      </div>
                      <div class="col-md-4">
                        <router-link :to="{ name: 'extracts' }">
                        <div class="boxPortal"><img src="/assets/Reports.png" alt="Image" class="rounded shadow-lg"><h3 class="text-light mt-3 text-uppercase" style="margin-bottom:-15px;letter-spacing:0.4rem;font-weight:500">Reports</h3>
                        </div>
                        </router-link>
                      </div>
                      <div class="col-md-4">
                        <router-link :to="{ name: 'admin.items.wine' }">
                        <div class="boxPortal"><img src="/assets/Admin.png" alt="Image" class="rounded shadow-lg"><h3 class="text-light mt-3 text-uppercase" style="margin-bottom:-15px;letter-spacing:0.4rem;font-weight:500">Admin</h3>
                        </div>
                        </router-link>
                      </div>
                  </div>
            </div>
                
        </div> <!-- end row wrapping everyting -->
        </transition>
      
              
        </div>

        <!--
        <div class="vineFooter" style="position: absolute;height: 91px;width: 100%;bottom: 0px;display: flex;justify-content: center;align-content: center;">
          <div class="vineFooterInner">
            <a href="http://www.facebook.com/coolvinesnewark">
            <img class="p-2" src="assets/facebookIcon.png" style="height:4.8rem;width:auto"></a>
            <a href="http://www.instagram.com/coolvineshoboken">
            <img class="p-2" src="assets/instagramIcon.png"style="height:4.8rem;width:auto"></a>
          </div>
        </div>  -->
      
    </section>

</div>

  
</template>

<script>
export default {
  data() {
    return {
      query: '',
      searchResultsVisible: false,
      
    }
  }
  
}
</script>

<style>
      html {
        scroll-behavior: smooth;
        overflow: hidden;
      }
      .navbar-light .navbar-nav .nav-link {
        color: rgba(73,80,87,.5);
        font-weight: 200;
        font-size: 20px; 
      }
     .boxPortal {
         background-color: rgba(0, 0, 0, 0.24);
         padding-top: 45px;
         padding-bottom: 45px;
         border-radius: 40px;
     }
      .extendVine {
        -webkit-transition: max-height 0.8s;
        -moz-transition: max-height 0.8s;
        transition: max-height 0.8s;
      }
      .searchScroll::-webkit-scrollbar {display:none;}
      .searchScroll {
        -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;
      }
      .fixRouterLink {
        font-weight: 400;

      }
      

      .slideVines-enter-active {
        animation: bounce-in 2s;
      }     
      .slideVines-leave-active {
        animation: bounce-in 0.5s reverse;
      }
      .bounce-transition {
  display: inline-block; /* otherwise scale animation won't work */
}
.bounce-enter {
  animation: bounce-in .5s;
}
.bounce-leave {
  animation: bounce-out .5s;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes bounce-out {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(0);
  }
}
    </style>
