<template>
<div
        :class="{
                    'spiritsMezcal2' : (previewValue.style.toLowerCase()).includes('mezcal'),
                    'spiritsTequila2' : (previewValue.style.toLowerCase()).includes('tequila'),
                    'spiritsGin2' : (previewValue.style.toLowerCase()).includes('gin'),
                    'spiritsVodka2' : (previewValue.style.toLowerCase()).includes('Vodka'),
                    'spiritsBourbon2' : (previewValue.style.toLowerCase()).includes('bourbon'),
                    'spiritsLiqueur2' : (previewValue.style.toLowerCase()).includes('liqueur'),
                    'spiritsBourbon2' : (previewValue.style.toLowerCase()).includes('bourbon'),
                    'spiritsRum2' : (previewValue.style.toLowerCase()).includes('rum'),
                    'spiritsIrish2' : (previewValue.style.toLowerCase()).includes('irish'),
                    'spiritsScotch2' : (previewValue.style.toLowerCase()).includes('scotch'),
                    'spiritsBrandy2' : (previewValue.style.toLowerCase()).includes('brandy'),
                    'spiritsVermouth2' : (previewValue.style.toLowerCase()).includes('vermouth'),
                    'spiritsSake2' : (previewValue.style.toLowerCase()).includes('sake'),
                    'spiritsRye2' : (previewValue.style.toLowerCase()).includes('rye'),
                    'spiritsMixers2' : (previewValue.style.toLowerCase()).includes('mixers'),
                    'spiritsDigestif2' : (previewValue.style.toLowerCase()).includes('digestif'),
                    'spiritsBitters2' : (previewValue.style.toLowerCase()).includes('bitters'),
                    'spiritsAperitif2' : (previewValue.style.toLowerCase()).includes('aperitif'),
                    'spiritsAmericanWhiskey2' : (previewValue.style.toLowerCase()).includes('american whiskey'),
                    'spiritsWorldWhiskey2' : (previewValue.style.toLowerCase()).includes('world whiskey'),
                    
                }" 
        style="position:relative;top:0px;left:0px;right:0px;width:420px;height:270px;padding-top:16Sspx;background-size:cover;border: 1px solid rgba(0,0,0,0.2)">
        
        <div style="position:absolute;top:90px;right:29px;height:45px;width:280px;">
            <h3 style="color:black;font-family:'Gotham-Bold';font-size:14pt;padding-top:7px;padding-left:8px;text-transform: uppercase;line-height:1.24rem;">
                {{previewValue.title}}
            </h3>
        </div>
        <!-- Description -->
        <div style="position:absolute;top:138px;right:28px;max-height:235px;width:280px;overflow:hidden;">
            <div style="position:relative;margin-top:0px;margin-left:7px;margin-right:7px;">
                <p style="font-family:'Gotham-Book';font-weight:300;font-size:10pt;line-height:1rem;">
                {{trimDescription(previewValue.shelf_description)}}
                   
                </p>
            </div>
        </div>
        <!-- Bottle Size -->
        
        <!-- Farming Practice -->
        <div style="position:absolute;top:85px;left:8px;height:61px;width:62px;">                   
            <div v-if="previewValue.farmpractice_organic" style='position:absolute;top:12px;left:12px;height:37px;width:38px;border-radius:24px;margin: 1px auto;padding:2px;background-image:url(https://bfapi.coolvines.com/img/farm/organic.png);background-size:contain;background-position:center center;background-repeat: no-repeat;'>
            </div>    
        </div>
        <div style="position:absolute;bottom:0px;left:0px;height:48px;width:107px;">
            <h3 style="color:black;font-size:24pt;padding-left:8px;padding-top:1px;font-family:'Gotham-Medium';font-weight:300;margin:0 auto;">
                
                ${{previewValue.price.split('.')[0]}}
            </h3>
        </div>
    </div>
</template>
<script>
export default {
    name: 'preview-spirits2',
    props: {
        previewValue: {},
    },
    methods: {
        returnOther() {
            if((this.previewValue.style.toLowerCase()).includes('mezcal') == false
                && (this.previewValue.style.toLowerCase()).includes('tequila') == false
                && (this.previewValue.style.toLowerCase()).includes('gin') == false 
                && (this.previewValue.style.toLowerCase()).includes('Vodka') == false
                && (this.previewValue.style.toLowerCase()).includes('bourbon') == false
                && (this.previewValue.style.toLowerCase()).includes('liqueur') == false
                && (this.previewValue.style.toLowerCase()).includes('bourbon') == false
                && (this.previewValue.style.toLowerCase()).includes('irish') == false
                && (this.previewValue.style.toLowerCase()).includes('rum') == false
                && (this.previewValue.style.toLowerCase()).includes('scotch') == false
                && (this.previewValue.style.toLowerCase()).includes('brandy') == false
                && (this.previewValue.style.toLowerCase()).includes('vermouth') == false
                && (this.previewValue.style.toLowerCase()).includes('sake') == false
                && (this.previewValue.style.toLowerCase()).includes('rye') == false
                && (this.previewValue.style.toLowerCase()).includes('mixers') == false
                && (this.previewValue.style.toLowerCase()).includes('digestif') == false
                && (this.previewValue.style.toLowerCase()).includes('bitters') == false
                && (this.previewValue.style.toLowerCase()).includes('aperitif') == false
                && (this.previewValue.style.toLowerCase()).includes('american whiskey') == false
                && (this.previewValue.style.toLowerCase()).includes('world whiskey') == false) {
                    console.log('testing value: ');
                    return true;
                } else {
                    console.log('no value');
                    return false;
                }
        },
        returnRed() {
            if((this.previewValue.grape_logo != 0)) {
                if((this.previewValue.grape_logo.split('/')[5]).split('_').includes('red')) {
                    return true;
                }
                
            } else {
                return false;
            }
        },
        returnWhite() {
            if((this.previewValue.grape_logo != 0)) {
                if((this.previewValue.grape_logo.split('/')[5]).split('_').includes('white')) {
                    return true;
                }
                
            } else {
                return false;
            }
        },
        returnRose() {
            if((this.previewValue.grape_logo != 0)) {
                if((this.previewValue.grape_logo.split('/')[5]).split('_').includes('rose.jpg')) {
                    return true;
                }
                
            } else {
                return false;
            }
        },
        returnOrange() {
            if((this.previewValue.grape_logo) == 0) {
                return true;
                
            } else {
                return false;
            }
        },
        splitTitle(title) {
            var st = title.split('-');
            return st[0];
        },
        splitSecondTitle(title) {
            var st = title.split(',');
            return st[0];
        },
        trimDescription(desc) {
            if(desc.length >= 260) {
                return desc.substring(0, 260) + '...';
            } else {
                return desc
            }
            
        },
    },
    mounted() {
        
    }
}
</script>
<style>

.spiritsTequila2 { background-image: url('https://bfapi.coolvines.com/img/spiritsh/tequila.PNG');  }
.spiritsMezcal2 { background-image: url('https://bfapi.coolvines.com/img/spiritsh/mezcal.PNG');  }
.spiritsGin2 { background-image: url('https://bfapi.coolvines.com/img/spiritsh/gin.PNG');  }   
.spiritsVodka2 { background-image: url('https://bfapi.coolvines.com/img/spiritsh/vodka.PNG');  }
.spiritsBourbon2 { background-image: url('https://bfapi.coolvines.com/img/spiritsh/bourbon.PNG');  }
.spiritsLiqueur2 { background-image: url('https://bfapi.coolvines.com/img/spiritsh/liqueur.PNG');  }
.spiritsRum2 { background-image: url('https://bfapi.coolvines.com/img/spiritsh/rum.PNG');  }
.spiritsIrish2 { background-image: url('https://bfapi.coolvines.com/img/spiritsh/irishwhiskey.PNG');  }
.spiritsScotch2 { background-image: url('https://bfapi.coolvines.com/img/spiritsh/scotch.PNG');  }
.spiritsBrandy2 { background-image: url('https://bfapi.coolvines.com/img/spiritsh/brandy.PNG');  }
.spiritsVermouth2 { background-image: url('https://bfapi.coolvines.com/img/spiritsh/vermouth.PNG');  }
.spiritsSake2 { background-image: url('https://bfapi.coolvines.com/img/spiritsh/sake.PNG');  }
.spiritsRye2 { background-image: url('https://bfapi.coolvines.com/img/spiritsh/rye.PNG');  }
.spiritsMixers2 { background-image: url('https://bfapi.coolvines.com/img/spiritsh/mixers.PNG');  }
.spiritsDigestif2 { background-image: url('https://bfapi.coolvines.com/img/spiritsh/digestif.PNG');  }
.spiritsBitters2 { background-image: url('https://bfapi.coolvines.com/img/spiritsh/bitters.PNG');  }
.spiritsAperitif2 { background-image: url('https://bfapi.coolvines.com/img/spiritsh/aperitif.PNG');  }
.spiritsAmericanWhiskey2 { background-image: url('https://bfapi.coolvines.com/img/spiritsh/americanwhiskey.PNG');  }
.spiritsWorldWhiskey2 { background-image: url('https://bfapi.coolvines.com/img/spiritsh/worldwhiskey.PNG');  }

</style>