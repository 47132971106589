<template>
<div>

    <section class="bg-primary-3 min-vh-100 jarallax d-flex align-items-center text-light" data-jarallax="" data-speed="0.7" style="
    display: flex;
    justify-content: center;
    align-items: center;
">
      <img src="assets/portalBackground.jpg" alt="Image" class="jarallax-img">


      <div class="container mt-6" style="width: calc(92%);margin: 0 auto;height: calc(90%);height: calc(75%);position: absolute;display: flex;justify-content: center;border-radius: 60px;max-width:1400px;">
        
        <div class="firstItemContainer">
          <div class="firstItemContainerLeft">
              <div class="firstItemContainerLeftHeader">
                  
                      <h3 class="text-dark" style="font-weight: 400;
                        margin-top: 18px;
                        letter-spacing: 0.174rem;
                        text-transform: uppercase;
                        font-size: 1.5rem;"
                        >Shelf Talkers</h3>
                  
              </div>
            <div class="secondItemLeft row justify-content-center" style="margin-top: 0 px;padding-top: 0px;">
                    
                    <shelf-item v-for="item in $store.state.shelf" :key="item.id" :item="item" style="border-right:0px solid white"></shelf-item>
                    
                    
                    
                </div>
          </div>
          <div class="firstItemContainerRight">
            <div class="firstItemContainerRightInner">
              
            
              <preview-wine v-if="generateValue == 'Wine -' && generateValue !== 'Spirits -'" :previewValue='previewValue' style="-webkit-transform:scale(1.2);"></preview-wine>
              <preview-spirits v-if="generateValue == 'Spirits -' && generateValue !== 'Wine -'" :previewValue='previewValue' style="-webkit-transform:scale(1.2);"></preview-spirits>
              <preview-spirits2 v-if="generateValue == 'Spirits - Horizontal' && generateValue !== 'Wine -'" :previewValue='previewValue' style="-webkit-transform:scale(1.2);"></preview-spirits2>
              <preview-beer v-if="generateValue == 'Beer -'" :previewValue='previewValue' style="-webkit-transform:scale(1.2);"></preview-beer>
              <preview-beer2 v-if="generateValue == 'Beer - Fridge'" :previewValue='previewValue' style="-webkit-transform:scale(1.2);"></preview-beer2>
              <preview-market v-if="generateValue == 'Market -'" :previewValue='previewValue' style="-webkit-transform:scale(1.2);"></preview-market>
              <preview-wine2 v-if="generateValue == 'Wine - Fridge'" :previewValue='previewValue' style="-webkit-transform:scale(1.2);"></preview-wine2>
              <preview-market2 v-if="generateValue == 'Market - Fridge'" :previewValue='previewValue' style="-webkit-transform:scale(1.2);"></preview-market2>
            
              
              <div v-else-if="generateValue == ''"  class="row">
              
                <div class="col-12">
                  <div class="itemHeader text-center">
                      <h2 class="text-center" style="color: #64646491;font-weight: 300;letter-spacing: 0.123rem;">
                          Preview Shelf
                      </h2>
                      <p style="color:#64646491;font-weight:300;margin-top:-4px">try selecting an item</p>
                  </div>
                </div>
              </div>
              
              
              
            </div>
          </div>

        </div>
        <div class="secondItemContainer">
          <div style="position:absolute;height:100%;width:32vh;right:0px;display:flex;align-items:center;justify-content:center;">
            
            <div class="form-group" style="width:20vh;">
                <select @change="layoutViewSelect" class="custom-select">
                  <option selected="">Select View</option>
                  <option v-for="i in printLayout" :key="i" :value="i">{{i}}</option>
                
                </select>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="injected-svg icon" data-src="assets/img/icons/interface/arrow-caret.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <path d="M14.4444 8.41358C14.7776 8.2281 15.1875 8.46907 15.1875 8.85048V15.1495C15.1875 15.5309 14.7776 15.7719 14.4444 15.5864L8.78505 12.4369C8.44258 12.2463 8.44258 11.7537 8.78505 11.5631L14.4444 8.41358Z" fill="#212529"></path>
                </svg>
            </div>
          </div>
          <div class="row mb-2 container-fluid justify-content-center">
            <div class="col-md-4 text-center mb-2 mt-1">
              
              <button v-if="!isGenerating" @click="generateShelfAlt" class="btn btn-primary" style="background-color: rgb(100, 43, 133); border-radius: 27px; border: 0px solid white; margin-top: 14px; margin-right: 24px;">
                <div class="px-6 py-1">Generate Shelf</div>
                
              </button>
              <div v-else class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
    
          </div>
        </div>
      </div>
    </section>
  
</div>
</template>
<script>
import { saveAs } from 'file-saver';
import shelfItem from "./comps/shelfItem";
import cartItem from "./comps/cartItem";
import previewWine from "./preview/PreviewWine";
import previewSpirits from "./preview/PreviewSpirits";
import previewSpirits2 from "./preview/PreviewSpirits2";
import previewBeer from "./preview/PreviewBeer";
import previewBeer2 from "./preview/PreviewBeer2";
import previewMarket from "./preview/PreviewMarket";
import previewWine2 from "./preview/PreviewWine2";
import previewMarket2 from "./preview/PreviewMarket2";
import axios from 'axios';

export default {
  components: {
    shelfItem,
    cartItem,
    previewWine,
    previewSpirits,
    previewSpirits2,
    previewBeer,
    previewBeer2,
    previewMarket,
    previewWine2,
    previewMarket2,
  },
  data() {
    return {
      isGenerating: false,
      printLayout: {
        wine: 'Wine -',
        wine2: "Wine - Fridge",
        spirits: 'Spirits -',
        spirits2: 'Spirits - Horizontal',
        beer: 'Beer -',
        beer2: 'Beer - Fridge',
        market: 'Market -',
        market2: 'Market - Fridge',

        shelf: 'Shelf Reference',
        
        
      },
      generateValue: '',
      previewItem: false,
      previewValue: {},
    }
  },
  methods: {
    logOutState() {
      this.$store.dispatch('destroyToken')
      .then(response => {
        this.$router.push({ name: 'Home' })
      })
    },
    generateShelf2() {
      console.log('generating shelf');
      
      axios.post('/api/shelf/generate', this.$store.state.shelf)
        .then(response => {
            console.log(response.data.array);
        });

    },
    generateShelf() {
      console.log('generating shelf');

      
      axios.post('/api/displaypdf', this.$store.state.shelf)
        .then(response => {
            
            var byteCharacters = atob(response.data);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], { type: "application/pdf" });
            var blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl);
            console.log(response.data.shelf)
            
        });

    },
    generateShelfAlt() {
      if(this.generateValue == 'Wine -') {
        this.isGenerating = true;
        console.log(localStorage.getItem('access_token_local'));

        if(this.$store.getters.loggedIn) {
          if(localStorage.getItem('access_token_local') == 'JC') {
              axios.post('/api/generatewinefulljc', this.$store.state.shelf).then(response => {
            
              var byteCharacters = atob(response.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], { type: "application/pdf" });
              var blobUrl = URL.createObjectURL(blob);
              window.open(blobUrl);
              console.log(response.data.shelf)
              
            }).then(data => {
                this.isGenerating = false;
            }).catch(data2 => {
              this.isGenerating = false;
              this.$toasted.error( 'Please try again')
            });
          } else if(localStorage.getItem('access_token_local') == 'NW') {
              axios.post('/api/generatewinefullnw', this.$store.state.shelf).then(response => {
            
              var byteCharacters = atob(response.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], { type: "application/pdf" });
              var blobUrl = URL.createObjectURL(blob);
              window.open(blobUrl);
              console.log(response.data.shelf)
              
            }).then(data => {
                this.isGenerating = false;
            }).catch(data2 => {
              this.isGenerating = false;
              this.$toasted.error( 'Please try again')
            });
          } else if(localStorage.getItem('access_token_local') == 'HB') {
              axios.post('/api/generatewinefullhb', this.$store.state.shelf).then(response => {
            
              var byteCharacters = atob(response.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], { type: "application/pdf" });
              var blobUrl = URL.createObjectURL(blob);
              window.open(blobUrl);
              console.log(response.data.shelf)
              
            }).then(data => {
                this.isGenerating = false;
            }).catch(data2 => {
              this.isGenerating = false;
              this.$toasted.error( 'Please try again')
            });
          } else {
              axios.post('/api/generatewinefull', this.$store.state.shelf).then(response => {
            
              var byteCharacters = atob(response.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], { type: "application/pdf" });
              var blobUrl = URL.createObjectURL(blob);
              window.open(blobUrl);
              console.log(response.data.shelf)
              
              }).then(data => {
                  this.isGenerating = false;
              }).catch(data2 => {
                this.isGenerating = false;
                this.$toasted.error( 'Please try again')
              });
          }
        }

        

      } else if(this.generateValue == 'Spirits -') {
        this.isGenerating = true;
        console.log(localStorage.getItem('access_token_local'));

        if(this.$store.getters.loggedIn) {
          if(localStorage.getItem('access_token_local') == 'JC') {
              axios.post('/api/generatespiritsjc', this.$store.state.shelf).then(response => {
            
              var byteCharacters = atob(response.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], { type: "application/pdf" });
              var blobUrl = URL.createObjectURL(blob);
              window.open(blobUrl);
              console.log(response.data.shelf)
              
            }).then(data => {
                this.isGenerating = false;
            }).catch(data2 => {
              this.isGenerating = false;
              this.$toasted.error( 'Please try again')
            });
          } else if(localStorage.getItem('access_token_local') == 'NW') {
              axios.post('/api/generatespiritsnw', this.$store.state.shelf).then(response => {
            
              var byteCharacters = atob(response.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], { type: "application/pdf" });
              var blobUrl = URL.createObjectURL(blob);
              window.open(blobUrl);
              console.log(response.data.shelf)
              
            }).then(data => {
                this.isGenerating = false;
            }).catch(data2 => {
              this.isGenerating = false;
              this.$toasted.error( 'Please try again')
            });
          } else if(localStorage.getItem('access_token_local') == 'HB') {
              axios.post('/api/generatespiritshb', this.$store.state.shelf).then(response => {
            
              var byteCharacters = atob(response.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], { type: "application/pdf" });
              var blobUrl = URL.createObjectURL(blob);
              window.open(blobUrl);
              console.log(response.data.shelf)
              
            }).then(data => {
                this.isGenerating = false;
            }).catch(data2 => {
              this.isGenerating = false;
              this.$toasted.error( 'Please try again')
            });
          } else {
              axios.post('/api/generatespiritsfull', this.$store.state.shelf).then(response => {
            
              var byteCharacters = atob(response.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], { type: "application/pdf" });
              var blobUrl = URL.createObjectURL(blob);
              window.open(blobUrl);
              console.log(response.data.shelf)
              
              }).then(data => {
                  this.isGenerating = false;
              }).catch(data2 => {
                this.isGenerating = false;
                this.$toasted.error( 'Please try again')
              });
          }
        }

      } else if(this.generateValue == 'Spirits - Horizontal') {
        this.isGenerating = true;
        console.log(localStorage.getItem('access_token_local'));

        if(this.$store.getters.loggedIn) {
          if(localStorage.getItem('access_token_local') == 'JC') {
              axios.post('/api/generatespiritshorizontaljc', this.$store.state.shelf).then(response => {
            
              var byteCharacters = atob(response.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], { type: "application/pdf" });
              var blobUrl = URL.createObjectURL(blob);
              window.open(blobUrl);
              console.log(response.data.shelf)
              
            }).then(data => {
                this.isGenerating = false;
            }).catch(data2 => {
              this.isGenerating = false;
              this.$toasted.error( 'Please try again')
            });
          } else if(localStorage.getItem('access_token_local') == 'NW') {
              axios.post('/api/generatespiritshorizontalnw', this.$store.state.shelf).then(response => {
            
              var byteCharacters = atob(response.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], { type: "application/pdf" });
              var blobUrl = URL.createObjectURL(blob);
              window.open(blobUrl);
              console.log(response.data.shelf)
              
            }).then(data => {
                this.isGenerating = false;
            }).catch(data2 => {
              this.isGenerating = false;
              this.$toasted.error( 'Please try again')
            });
          } else if(localStorage.getItem('access_token_local') == 'HB') {
              axios.post('/api/generatespiritshorizontalhb', this.$store.state.shelf).then(response => {
            
              var byteCharacters = atob(response.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], { type: "application/pdf" });
              var blobUrl = URL.createObjectURL(blob);
              window.open(blobUrl);
              console.log(response.data.shelf)
              
            }).then(data => {
                this.isGenerating = false;
            }).catch(data2 => {
              this.isGenerating = false;
              this.$toasted.error( 'Please try again')
            });
          } else {
              axios.post('/api/generatespiritshorizontal', this.$store.state.shelf).then(response => {
            
              var byteCharacters = atob(response.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], { type: "application/pdf" });
              var blobUrl = URL.createObjectURL(blob);
              window.open(blobUrl);
              console.log(response.data.shelf)
              
              }).then(data => {
                  this.isGenerating = false;
              }).catch(data2 => {
                this.isGenerating = false;
                this.$toasted.error( 'Please try again')
              });
          }
        }

      } else if(this.generateValue == 'Beer -') {
        this.isGenerating = true;
        console.log(localStorage.getItem('access_token_local'));

        if(this.$store.getters.loggedIn) {
          if(localStorage.getItem('access_token_local') == 'JC') {
              axios.post('/api/generatebeerjc', this.$store.state.shelf).then(response => {
            
              var byteCharacters = atob(response.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], { type: "application/pdf" });
              var blobUrl = URL.createObjectURL(blob);
              window.open(blobUrl);
              console.log(response.data.shelf)
              
            }).then(data => {
                this.isGenerating = false;
            }).catch(data2 => {
              this.isGenerating = false;
              this.$toasted.error( 'Please try again')
            });
          } else if(localStorage.getItem('access_token_local') == 'NW') {
              axios.post('/api/generatebeernw', this.$store.state.shelf).then(response => {
            
              var byteCharacters = atob(response.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], { type: "application/pdf" });
              var blobUrl = URL.createObjectURL(blob);
              window.open(blobUrl);
              console.log(response.data.shelf)
              
            }).then(data => {
                this.isGenerating = false;
            }).catch(data2 => {
              this.isGenerating = false;
              this.$toasted.error( 'Please try again')
            });
          } else if(localStorage.getItem('access_token_local') == 'HB') {
              axios.post('/api/generatebeerhb', this.$store.state.shelf).then(response => {
            
              var byteCharacters = atob(response.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], { type: "application/pdf" });
              var blobUrl = URL.createObjectURL(blob);
              window.open(blobUrl);
              console.log(response.data.shelf)
              
            }).then(data => {
                this.isGenerating = false;
            }).catch(data2 => {
              this.isGenerating = false;
              this.$toasted.error( 'Please try again')
            });
          } else {
              axios.post('/api/generatebeerfull', this.$store.state.shelf).then(response => {
            
              var byteCharacters = atob(response.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], { type: "application/pdf" });
              var blobUrl = URL.createObjectURL(blob);
              window.open(blobUrl);
              console.log(response.data.shelf)
              
              }).then(data => {
                  this.isGenerating = false;
              }).catch(data2 => {
                this.isGenerating = false;
                this.$toasted.error( 'Please try again')
              });
          }
        }

      } else if(this.generateValue == 'Beer - Fridge') {
        this.isGenerating = true;
        console.log(localStorage.getItem('access_token_local'));
        axios.post('/api/generatebeerfridge', this.$store.state.shelf)
        .then(response => {
            
            var byteCharacters = atob(response.data);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], { type: "application/pdf" });
            var blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl);
            console.log(response.data.shelf)
            
        }).then(data => {
            this.isGenerating = false;
        }).catch(data2 => {
          this.isGenerating = false;
          this.$toasted.error( 'Please try again')
        });

      } else if(this.generateValue == 'Shelf Reference') {
        this.isGenerating = true;
        console.log(localStorage.getItem('access_token_local'));
        axios.post('/api/generateshelfreference', this.$store.state.shelf).then(response => {
            
              var byteCharacters = atob(response.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], { type: "application/pdf" });
              var blobUrl = URL.createObjectURL(blob);
              window.open(blobUrl);
              console.log(response.data.shelf)
              
            }).then(data => {
                this.isGenerating = false;
            }).catch(data2 => {
              this.isGenerating = false;
              this.$toasted.error( 'Please try again')
            });

        

      } else if(this.generateValue == 'Market -') {
        this.isGenerating = true;
        console.log(localStorage.getItem('access_token_local'));
        axios.post('/api/generatemarket', this.$store.state.shelf).then(response => {
            
              var byteCharacters = atob(response.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], { type: "application/pdf" });
              var blobUrl = URL.createObjectURL(blob);
              window.open(blobUrl);
              console.log(response.data.shelf)
              
            }).then(data => {
                this.isGenerating = false;
            }).catch(data2 => {
              this.isGenerating = false;
              this.$toasted.error( 'Please try again')
            });

        

      } else if(this.generateValue == 'Market - Fridge') {
        this.isGenerating = true;
        console.log(localStorage.getItem('access_token_local'));
        axios.post('/api/generatemarketfridge', this.$store.state.shelf).then(response => {
            
              var byteCharacters = atob(response.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], { type: "application/pdf" });
              var blobUrl = URL.createObjectURL(blob);
              window.open(blobUrl);
              console.log(response.data.shelf)
              
            }).then(data => {
                this.isGenerating = false;
            }).catch(data2 => {
              this.isGenerating = false;
              this.$toasted.error( 'Please try again')
            });

        

      } else if(this.generateValue == 'Wine - Fridge') {
        this.isGenerating = true;
        if(this.$store.getters.loggedIn) {
          if(localStorage.getItem('access_token_local') == 'JC') {
              axios.post('/api/generatewinefridgejc', this.$store.state.shelf).then(response => {
            
              var byteCharacters = atob(response.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], { type: "application/pdf" });
              var blobUrl = URL.createObjectURL(blob);
              window.open(blobUrl);
              console.log(response.data.shelf)
              
            }).then(data => {
                this.isGenerating = false;
            }).catch(data2 => {
              this.isGenerating = false;
              this.$toasted.error( 'Please try again')
            });
          } else if(localStorage.getItem('access_token_local') == 'NW') {
              axios.post('/api/generatewinefridgenw', this.$store.state.shelf).then(response => {
            
              var byteCharacters = atob(response.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], { type: "application/pdf" });
              var blobUrl = URL.createObjectURL(blob);
              window.open(blobUrl);
              console.log(response.data.shelf)
              
            }).then(data => {
                this.isGenerating = false;
            }).catch(data2 => {
              this.isGenerating = false;
              this.$toasted.error( 'Please try again')
            });
          } else if(localStorage.getItem('access_token_local') == 'HB') {
              axios.post('/api/generatewinefridgehb', this.$store.state.shelf).then(response => {
            
              var byteCharacters = atob(response.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], { type: "application/pdf" });
              var blobUrl = URL.createObjectURL(blob);
              window.open(blobUrl);
              console.log(response.data.shelf)
              
            }).then(data => {
                this.isGenerating = false;
            }).catch(data2 => {
              this.isGenerating = false;
              this.$toasted.error( 'Please try again')
            });
          } else {
              axios.post('/api/generatewinefridgefull', this.$store.state.shelf).then(response => {
            
              var byteCharacters = atob(response.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], { type: "application/pdf" });
              var blobUrl = URL.createObjectURL(blob);
              window.open(blobUrl);
              console.log(response.data.shelf)
              
              }).then(data => {
                  this.isGenerating = false;
              }).catch(data2 => {
                this.isGenerating = false;
                this.$toasted.error( 'Please try again')
              });
          }
        }

        

      } else {
       
        this.$toasted.error( 'Select a view!')
        console.log('not sure..');
      }
    },
    layoutViewSelect(event) {
      this.generateValue = event.target.value;
      if(event.target.value == 'Wine') {
        console.log('wine wine wine ' + this.generateValue);
      } else if(event.target.value == 'Core Wine') {
        console.log('corrreeeeee ' + this.generateValue);
      }
    },
    previewShelf(i) {
      console.log('testing preview option');
      console.log(i);
      this.previewValue = i;
      this.previewItem = true;
      if(this.generateValue == "") {
        this.$toasted.error( 'Select a view!')
      }
    }
  }
}
</script>


<style scoped>
      html {
        scroll-behavior: smooth;
      }
      .navbar-light .navbar-nav .nav-link {
        color: rgba(73,80,87,.5);
        font-weight: 200;
        font-size: 20px; 
      }

      
      .firstItemContainer {
        height: calc(90%);
        width: 100%;
        background-color: white;
        margin: 0 auto;
        position: absolute;
        top: 0px;
        display: flex;
        overflow: hidden;
        border-radius: 34px 34px 0 0;
      }
      .secondItemContainer {
        height: calc(12%);
        width: 100%;
        background-color: white;
        margin: 0 auto;
        position: absolute;
        bottom: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 0 0 34px 34px;
        color: black;
        border-top: 1px solid rgba(0,0,0,0.1);
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.12rem;
      }
      .secondItemContainer .span {
        color: white;
      }
      .firstItemContainerLeft {
        height: 100%;
        width: calc(48%);
        
        margin: 0 auto;
        position: absolute;
        
       
        overflow: hidden;
        border-right: 1.5px solid rgba(0,0,0,0.1);
      }
      .firstItemContainerLeftHeader {
          height: 10vh;
          width: 100%;
          top: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 0.4px solid rgba(0,0,0,0.1);
          
      }
      .secondItemLeft::-webkit-scrollbar {display:none;}
      .secondItemLeft {
        height: 56vh;
        
        overflow-x: hidden;
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;
        
      }
      .overFlowFistItem {
          overflow-x: hidden;
          overflow-y: scroll;
          
          height: 80%;
          width: 100%;
          position: absolute;
          
        
      }
      .firstItemContainerRight {
        height: 100%;
        width: calc(52%);
        
        margin: 0 auto;
        position: absolute;
        right: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .firstItemContainerRightInner {
        height: calc(90%);
        width: calc(90%);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        
        
        color: black;
        
        margin: 0 auto;
      }
      .firstItemContainerRightInner .row {
          margin-top: -74px;
      }
      
      .dropdown-menu-hidescroll::-webkit-scrollbar {display:none;}
      .dropdown-menu-hidescroll {
        -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;
      }
      .fixLink {
        color: red;
      }
        
        i:hover {
          
          color: rgba(0, 0, 0, 0.493);
         
        }
        
    .topScoreImage >>> img {
      height:25px;
      width:25px;
      display:list-item;
      background-color:red;
      margin: 4px 4px;
      border-radius: 4px;
    }
</style>

