var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"relative","top":"0px","left":"0px","right":"0px","width":"402px","height":"120px","background-size":"cover","border":"1px solid rgba(0,0,0,0.2)"}},[_c('div',{staticStyle:{"position":"absolute","top":"4px","left":"95px","height":"50px","width":"255px"}},[_c('h4',{staticStyle:{"color":"black","font-family":"'Gotham-Bold'","margin-top":"7px","font-size":"14pt","padding-top":"0px","padding-left":"6px","text-transform":"uppercase","line-height":"1.32rem","letter-spacing":"0.01rem"}},[_vm._v(" "+_vm._s(_vm.previewValue.title.split('-')[0])+" ")])]),_c('div',{staticStyle:{"position":"absolute","left":"95px","top":"60px","height":"55px","width":"195px","overflow":"hidden"}},[_c('div',{staticStyle:{"position":"relative","left":"8px","max-width":"170px"}},[_c('span',{staticStyle:{"color":"black","font-family":"'Gotham-MediumIta'","line-height":"0.74rem","font-style":"italic","font-size":"14pt"}},[_vm._v(" "+_vm._s(_vm.previewValue.varietal.split('/')[0].split(',')[0])+" ")])])]),_c('div',{class:{
                   
                    'colorBannerRed' : (_vm.previewValue.wine_type.toLowerCase()).includes('red'),
                    'colorBannerWhite' : (_vm.previewValue.wine_type.toLowerCase()).includes('white'),
                    'colorBannerRose' : (_vm.previewValue.wine_type.toLowerCase()).includes('rose'),
                    'colorBannerOrange' : _vm.returnOther(),
                },staticStyle:{"position":"absolute","left":"3px","top":"3px","height":"112px","width":"82px"}},[_c('div',{staticStyle:{"position":"absolute","top":"26px","left":"9px","height":"64px","width":"64px","border-radius":"34px","background-color":"white"}}),_c('div',{class:{
                    'bodyFull' : (_vm.previewValue.body.toLowerCase()).includes('full'),
                    'bodyMedium' : (_vm.previewValue.body.toLowerCase()).includes('medium'),
                    'bodyLight' : (_vm.previewValue.body.toLowerCase()).includes('light'),
                    'bodyFortified' : (_vm.previewValue.body.toLowerCase()).includes('fortified'),
                    'bodySparkling' : (_vm.previewValue.body.toLowerCase()).includes('sparkling'),
                    'bodySweet' : (_vm.previewValue.body.toLowerCase()).includes('sweet'),
                },staticStyle:{"position":"absolute","top":"17px","left":"1px","height":"80px","width":"80px","background-size":"contain","background-position":"center center","background-repeat":"no-repeat","z-index":"44"}})]),_c('div',{staticStyle:{"position":"absolute","bottom":"10px","right":"0px","height":"34px","width":"75px","overflow":"hidden"}},[_c('h3',{staticStyle:{"color":"black","font-size":"24pt","padding-left":"8px","padding-top":"1px","font-family":"'Gotham-Medium'","font-weight":"300","margin":"0 auto"}},[_vm._v(" $"+_vm._s(_vm.previewValue.price.split('.')[0])+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }