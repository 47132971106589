<template>
<div
        :class="{
                    'spiritsMezcal' : (previewValue.style.toLowerCase()).includes('mezcal'),
                    'spiritsTequila' : (previewValue.style.toLowerCase()).includes('tequila'),
                    'spiritsGin' : (previewValue.style.toLowerCase()).includes('gin'),
                    'spiritsVodka' : (previewValue.style.toLowerCase()).includes('Vodka'),
                    'spiritsBourbon' : (previewValue.style.toLowerCase()).includes('bourbon'),
                    'spiritsLiqueur' : (previewValue.style.toLowerCase()).includes('liqueur'),
                    'spiritsBourbon' : (previewValue.style.toLowerCase()).includes('bourbon'),
                    'spiritsRum' : (previewValue.style.toLowerCase()).includes('rum'),
                    'spiritsScotch' : (previewValue.style.toLowerCase()).includes('scotch'),
                    'spiritsIrish' : (previewValue.style.toLowerCase()).includes('irish'),
                    'spiritsBrandy' : (previewValue.style.toLowerCase()).includes('brandy'),
                    'spiritsVermouth' : (previewValue.style.toLowerCase()).includes('vermouth'),
                    'spiritsSake' : (previewValue.style.toLowerCase()).includes('sake'),
                    'spiritsRye' : (previewValue.style.toLowerCase()).includes('rye'),
                    'spiritsMixers' : (previewValue.style.toLowerCase()).includes('mixers'),
                    'spiritsDigestif' : (previewValue.style.toLowerCase()).includes('digestif'),
                    'spiritsBitters' : (previewValue.style.toLowerCase()).includes('bitters'),
                    'spiritsApertif' : (previewValue.style.toLowerCase()).includes('apertif'),
                    'spiritsAmericanWhiskey' : (previewValue.style.toLowerCase()).includes('american whiskey'),
                    'spiritsWorldWhiskey' : (previewValue.style.toLowerCase()).includes('world whiskey'),
                    'spiritsGeneral' : returnOther(),
                }" 
        style="position:relative;top:0px;left:0px;right:0px;width:270px;height:420px;padding-top:16Sspx;background-size:cover;border: 1px solid rgba(0,0,0,0.2)">
        <div style="position:absolute;top:0px;right:0px;height:95px;width:189px;">
            <h3 style="color:black;font-family:'Gotham-Bold';font-size:14pt;padding-top:7px;padding-left:8px;text-transform: uppercase;line-height:1.24rem;">
                {{previewValue.title}}
            </h3>
        </div>
        <!-- Description -->
        <div style="position:absolute;top:100px;right:0px;max-height:235px;width:189px;overflow:hidden;">
            <div style="position:relative;margin-top:0px;margin-left:7px;margin-right:7px;">
                <p style="font-family:'Gotham-Book';font-weight:300;font-size:10pt;line-height:1rem;">
                {{previewValue.shelf_description}}
                   
                </p>
            </div>
        </div>
        <!-- Bottle Size -->
        <div style="position:absolute;top:338px;left:80px;height:32px;width:107px;">
            <h3 v-if="previewValue.size" style="color:black;font-size:15pt;padding-left:7px;padding-top:6px;font-family:'Gotham-Light';font-weight:200;margin:0 auto;text-transform: uppercase;">
                
                ({{previewValue.size}})
                
            </h3>          
        </div>
        <!-- Price -->
        <div style="position:absolute;top:371px;left:80px;height:48px;width:107px;">
            <h3 style="color:black;font-size:24pt;padding-left:8px;padding-top:1px;font-family:'Gotham-Medium';font-weight:300;margin:0 auto;">
                
                ${{previewValue.price.split('.')[0]}}
            </h3>
        </div>
        <!-- Farming Practice -->
        <div style="position:absolute;top:359px;right:0px;height:61px;width:62px;">
                    
                        
            <div v-if="previewValue.farmpractice_organic" style='position:absolute;top:12px;left:12px;height:37px;width:38px;border-radius:24px;margin: 1px auto;padding:2px;background-image:url(https://bfapi.coolvines.com/img/farm/organic.png);background-size:contain;background-position:center center;background-repeat: no-repeat;'>
            </div>
                    
            
            
        </div>
        <!-- QR -->
        <div style="position:absolute;bottom:0px;left:0px;height:82px;width:79px;">
            <div style="position:absolute;top:9px;left:6px;height:62px;width:64px;">
                      
               
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'preview-spirits',
    props: {
        previewValue: {},
    },
    methods: {
        returnOther() {
            if((this.previewValue.style.toLowerCase()).includes('mezcal') == false
                && (this.previewValue.style.toLowerCase()).includes('tequila') == false
                && (this.previewValue.style.toLowerCase()).includes('gin') == false 
                && (this.previewValue.style.toLowerCase()).includes('Vodka') == false
                && (this.previewValue.style.toLowerCase()).includes('bourbon') == false
                && (this.previewValue.style.toLowerCase()).includes('liqueur') == false
                && (this.previewValue.style.toLowerCase()).includes('bourbon') == false
                && (this.previewValue.style.toLowerCase()).includes('rum') == false
                && (this.previewValue.style.toLowerCase()).includes('irish') == false
                && (this.previewValue.style.toLowerCase()).includes('scotch') == false
                && (this.previewValue.style.toLowerCase()).includes('brandy') == false
                && (this.previewValue.style.toLowerCase()).includes('vermouth') == false
                && (this.previewValue.style.toLowerCase()).includes('sake') == false
                && (this.previewValue.style.toLowerCase()).includes('rye') == false
                && (this.previewValue.style.toLowerCase()).includes('mixers') == false
                && (this.previewValue.style.toLowerCase()).includes('digestif') == false
                && (this.previewValue.style.toLowerCase()).includes('bitters') == false
                && (this.previewValue.style.toLowerCase()).includes('aperitif') == false
                && (this.previewValue.style.toLowerCase()).includes('american whiskey') == false
                && (this.previewValue.style.toLowerCase()).includes('world whiskey') == false) {
                    console.log('testing value: ');
                    return true;
                } else {
                    console.log('no value');
                    return false;
                }
        },
        returnRed() {
            if((this.previewValue.grape_logo != 0)) {
                if((this.previewValue.grape_logo.split('/')[5]).split('_').includes('red')) {
                    return true;
                }
                
            } else {
                return false;
            }
        },
        returnWhite() {
            if((this.previewValue.grape_logo != 0)) {
                if((this.previewValue.grape_logo.split('/')[5]).split('_').includes('white')) {
                    return true;
                }
                
            } else {
                return false;
            }
        },
        returnRose() {
            if((this.previewValue.grape_logo != 0)) {
                if((this.previewValue.grape_logo.split('/')[5]).split('_').includes('rose.jpg')) {
                    return true;
                }
                
            } else {
                return false;
            }
        },
        returnOrange() {
            if((this.previewValue.grape_logo) == 0) {
                return true;
                
            } else {
                return false;
            }
        },
        splitTitle(title) {
            var st = title.split('-');
            return st[0];
        },
        splitSecondTitle(title) {
            var st = title.split(',');
            return st[0];
        },
    },
    mounted() {
        
    }
}
</script>
<style>

.spiritsTequila { background-image: url('https://bfapi.coolvines.com/img/spirits/Tequila.PNG');  }
.spiritsMezcal { background-image: url('https://bfapi.coolvines.com/img/spirits/Mezcal.PNG');  }
.spiritsGin { background-image: url('https://bfapi.coolvines.com/img/spirits/Gin.PNG');  }   
.spiritsVodka { background-image: url('https://bfapi.coolvines.com/img/spirits/Vodka.PNG');  }
.spiritsBourbon { background-image: url('https://bfapi.coolvines.com/img/spirits/Bourbon.PNG');  }
.spiritsLiqueur { background-image: url('https://bfapi.coolvines.com/img/spirits/Liqueur.PNG');  }
.spiritsRum { background-image: url('https://bfapi.coolvines.com/img/spirits/Rum.PNG');  }
.spiritsIrish { background-image: url('https://bfapi.coolvines.com/img/spirits/IrishWhiskey.PNG');  }
.spiritsScotch { background-image: url('https://bfapi.coolvines.com/img/spirits/Scotch.PNG');  }
.spiritsBrandy { background-image: url('https://bfapi.coolvines.com/img/spirits/Brandy.PNG');  }
.spiritsVermouth { background-image: url('https://bfapi.coolvines.com/img/spirits/Vermouth.PNG');  }
.spiritsSake { background-image: url('https://bfapi.coolvines.com/img/spirits/Sake.PNG');  }
.spiritsRye { background-image: url('https://bfapi.coolvines.com/img/spirits/Rye.PNG');  }
.spiritsMixers { background-image: url('https://bfapi.coolvines.com/img/spirits/Mixers.PNG');  }
.spiritsDigestif { background-image: url('https://bfapi.coolvines.com/img/spirits/Digestif.PNG');  }
.spiritsBitters { background-image: url('https://bfapi.coolvines.com/img/spirits/Bitters.PNG');  }
.spiritsApertif { background-image: url('https://bfapi.coolvines.com/img/spirits/Apertif.PNG');  }
.spiritsAmericanWhiskey { background-image: url('https://bfapi.coolvines.com/img/spirits/AmericanWhiskey.PNG');  }
.spiritsWorldWhiskey { background-image: url('https://bfapi.coolvines.com/img/spirits/WorldWhiskey.PNG');  }
.spiritsGeneral { background-image: url('https://bfapi.coolvines.com/img/spirits/General.PNG');  }
</style>