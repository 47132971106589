var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
                   
                    'beerBelgian2' : (_vm.previewValue.style.toLowerCase()).includes('belgian ale') || (_vm.previewValue.style.toLowerCase()).includes('trappist'),
                    'beerGoldenAle2': (_vm.previewValue.style.toLowerCase()).includes('golden ale'),
                    'beerDarkAle2': (_vm.previewValue.style.toLowerCase()).includes('dark ale'),
                    'beerPaleAle2': (_vm.previewValue.style.toLowerCase()).includes('pale ale') || (_vm.previewValue.style.toLowerCase()).includes('saison'),
                    'beerLager2': (_vm.previewValue.style.toLowerCase()).includes('lager'),
                    'beerCider2': (_vm.previewValue.style.toLowerCase()).includes('cider'),
                    'beerStout2': (_vm.previewValue.style.toLowerCase()).includes('stout'),
                    
                    'beerFarmhouse2': (_vm.previewValue.style.toLowerCase()).includes('farmhouse'),
                    'beerGluten2': _vm.previewValue.style.includes('Gluten'),
                    'beerHardSeltzer2': (_vm.previewValue.style.toLowerCase()).includes('hard seltzer'),
                    'beerMead2': (_vm.previewValue.style.toLowerCase()).includes('mead'),
                    'beerIPA2': (_vm.previewValue.style.toLowerCase()).includes('ipa'),
                    'beerPilsner2': (_vm.previewValue.style.toLowerCase()).includes('pilsner'),
                    'beerPorter2': (_vm.previewValue.style.toLowerCase()).includes('porter'),
                    'beerSour2': (_vm.previewValue.style.toLowerCase()).includes('sour'),
                    'beerWheat2': (_vm.previewValue.style.toLowerCase()).includes('wheat'),
                    'beerOther2': _vm.returnOther(),
                },staticStyle:{"position":"relative","top":"0px","left":"0px","right":"0px","width":"402px","height":"115px","background-size":"cover","border":"1px solid rgba(0,0,0,0.2)"}},[_c('div',{staticStyle:{"position":"absolute","top":"0px","left":"48px","height":"95px","width":"294px"}},[_c('h3',{staticStyle:{"color":"black","font-family":"'Gotham-Bold'","font-size":"14pt","padding-top":"7px","padding-left":"6px","text-transform":"uppercase","line-height":"1.32rem","letter-spacing":"0.01rem"}},[_vm._v(" "+_vm._s(_vm.previewValue.title)+" ")])]),(_vm.previewValue.farmpractice_limited)?_c('div',{staticStyle:{"position":"absolute","top":"0px","right":"0px","height":"60px","width":"62px"}},[_c('div',{staticStyle:{"position":"absolute","top":"12px","left":"12px","height":"37px","width":"38px","border-radius":"24px","margin":"1px auto","padding":"2px","background-image":"url(http://bfapi.coolvines.com/img/farm/limited.png)","background-size":"contain","background-position":"center center","background-repeat":"no-repeat"}})]):_vm._e(),_c('div',{staticStyle:{"position":"absolute","top":"90px","left":"164px","height":"27px","width":"175px"}},[_c('h3',{staticStyle:{"color":"black","font-size":"11pt","padding-left":"10px","padding-top":"6px","font-family":"'Gotham-Light'","font-weight":"200","margin":"0 auto"}},[_vm._v(" "+_vm._s(_vm.previewValue.size)+" ")])]),_c('div',{staticStyle:{"position":"absolute","bottom":"0px","right":"0px","height":"34px","width":"75px","overflow":"hidden"}},[_c('h3',{staticStyle:{"color":"black","font-size":"24pt","padding-left":"8px","padding-top":"1px","font-family":"'Gotham-Medium'","font-weight":"300","margin":"0 auto"}},[_vm._v(" $"+_vm._s(_vm.previewValue.price.split('.')[0])+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }