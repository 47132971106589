import NProgress from 'nprogress';
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Home2 from '../views/Home2.vue'
import LogIn from '../views/LogIn.vue'
import LogOut from '../views/Logout.vue'
import Portal from '../views/Portal.vue'
import Shelf from '../views/Shelf.vue'



const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  
 
  {
    path: '/item/:slug/',
    name: 'test',
    component: () => import(/* webpackChunkName: "test" */ '../views/Test.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LogIn.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "logout" */ '../views/Logout.vue')
  },
  {
    path: '/portal',
    name: 'portal',
    component: () => import(/* webpackChunkName: "portal" */ '../views/Portal.vue'),
    meta: { requiresAuth: true  }
  },
  {
    path: '/extracts',
    name: 'extracts',
    component: () => import(/* webpackChunkName: "portal" */ '../views/Extracts.vue'),
    meta: { requiresAuth: true  }
  },
  
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "admin.items" */ '../views/AdminView.vue'),
    children: [
      {
        path: '/admin/:type/',
        name: 'admin.items',
        component: () => import(/* webpackChunkName: "admin.items" */ '../views/AdminViewItemsWine.vue'),
      },
      {
        path: '/admin/items/wine',
        name: 'admin.items.wine',
        component: () => import(/* webpackChunkName: "admin.items" */ '../views/AdminViewItemsWine.vue'),
      },
      {
        path: '/admin/items/spirits',
        name: 'admin.items.spirits',
        component: () => import(/* webpackChunkName: "admin.items" */ '../views/AdminViewItemsSpirits.vue'),
      },
      {
        path: '/admin/items/beer',
        name: 'admin.items.beer',
        component: () => import(/* webpackChunkName: "admin.items" */ '../views/AdminViewItemsBeer.vue'),
      },
      {
        path: '/admin/items/food',
        name: 'admin.items.food',
        component: () => import(/* webpackChunkName: "admin.items" */ '../views/AdminViewItemsFood.vue'),
      },
      {
        path: '/admin/items/:slug/',
        name: 'admin.items.view',
        component: () => import(/* webpackChunkName: "admin.items" */ '../views/AdminViewItemsView.vue'),
      },
      {
        path: '/admin/users/',
        name: 'admin.users',
        component: () => import(/* webpackChunkName: "admin.users" */ '../views/AdminViewUsers2.vue'),
        meta: { requiresAuth2: true  }
      },
      {
        path: '/admin/users/:email/',
        name: 'admin.users.view',
        component: () => import(/* webpackChunkName: "admin.users" */ '../views/AdminViewUsersView.vue'),
        meta: { requiresAuth2: true  }
      },
      {
        path: '/admin/createuser',
        name: 'admin.users.add',
        component: () => import(/* webpackChunkName: "admin.users" */ '../views/AdminViewUsersAdd.vue'),
        meta: { requiresAuth2: true  }
      },
      {
        path: '/admin/items/add',
        name: 'admin.shelf.add',
        component: () => import(/* webpackChunkName: "admin.users" */ '../views/AdminShelfAdd.vue'),
        meta: { requiresAuth2: true  }
      },
    ]
  },
  {
    path: '/shelf',
    name: 'shelf',
    component: () => import(/* webpackChunkName: "shelf" */ '../views/Shelf.vue'),
    meta: { requiresAuth: true  }
  },
 
]



Vue.use(VueRouter)
NProgress.configure({
  showSpinner: false,
  easing: 'ease',
  speed: 600,
});

const router = createRouter();
export default router;

function createRouter() {
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
  });

 
  return router;

}




