<template>
<div class="col-md-12" style="max-width:100%;border-bottom: 0px solid rgb(95, 95, 95);border-bottom:1px solid #e6e6e6;" >
                     
                        <div style="width:100%;border-top: 0px solid #e6e6e6;border-left: 1px solid #e6e6e6;border-right: 0px solid #e6e6e6;" class="p-2">
                          <div class="row no-gutters align-content-center" style="">
                            <div class="col-8" style="display: flex;justify-content: center;align-items: center;">
                              <div @click="$parent.previewShelf(item)" class="row">
                                <div class="col-2"></div>
                                <div class="col-10 text-center" style="margin-top: ;padding-bottom: 4px;margin-top: -4px;">
                                  <span style="font-size: 1.4rem;margin-left: ;color: rgb(83, 83, 83);margin-top: ;padding-top: -10px;">
                                    {{ item.title }}</span>
                                </div>
                                <div class="col-2"></div>
                                <div class="col-4 text-center topScore" v-html="item.topscore">

                                </div>
                                <div class="col-6 text-left">
                                  <span style="color: #7e7e7e;" v-html="item.varietal"></span>
                                </div>
                              </div>   
                              <div class="row">
                                
                              </div>  
                            <div style="position:absolute;top:0xp;left:0px;height:100%;width:49px;display:flex;align-items:center;">
                              <div class="hoverAddToShelf" style="height:30px;width:30px;">
                               <button @click="remFromShelf(item.id)" class="styleAddToShelf">
                                <i class="uil uil-multiply" style=""></i>

                                </button>
                              </div>
                            </div>        
                            </div>
      
                            <div  @click="$parent.previewShelf(item)" class="col-md-4" style=";align-items:center;display:flex;justify-content:center;">
                              <a href="#" class="py-1">
                                <div class="position-relative" style="display: flex;justify-content: center;align-items: center;">
                                  <img v-if="item.img_src" :src="item.img_src" style="border-radius: 0px;height: 80px;width: auto;" class="img-fluid">
                                  <div v-else style="height:80px;width:auto;object-fit:contain;display:flex;align-items:center;justify-content:center;" class="rounded mb-4">
                                    <i class="uil uil-capture" style="font-size:4rem;color:rgba(0,0,0,0.1)"></i>
                                  </div>
                                </div>
                                
                              </a>
                            </div> <!-- end col 4-->
                            
                            
                          </div>
                        </div>
                        
                     
                        
                    </div>
</template>
<script>

export default {
    name: "shelf-item",
    props: {
        index: {},
        item: {},
    },
    methods: {
        preview() {
            console.log("preview selected");
        },
        remFromShelf(id) {
            console.log("...adding " + this.index);
            this.$store.commit('removeFromShelf', id);
            this.$toasted.success('Removed');
        },
        showPreview() {
          console.log("showing preview");
        }
    }

    
}
</script>
<style scoped>
.topScore >>> img {
  padding-left: 1.4px;
  padding-right: 1.4px;
}
.hoverAddToShelf:hover {
    cursor: pointer;
    scale: 1.2rem;
}
.styleAddToShelf {
  outline: 0ch;
  border: 0px solid white;
  background-color: white;
  font-size:0.64rem;

}
.styleAddToShelf:hover {
  color:rgb(100, 43, 133);
 
 
  font-size: 0.85rem;
  margin-left: -1px;
  margin-top: 3px;
}


</style>
