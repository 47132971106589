<template>
<div
        style="position:relative;top:0px;left:0px;right:0px;width:270px;height:420px; background-image: url('https://bfapi.coolvines.com/img/market/market-full.PNG');background-size:contain;background-position;center center;border: 1px solid rgba(0,0,0,0.2)">
        <div style="position:absolute;top:94px;left:37px;height:95px;width:189px;">
            <h3 style="color:black;font-family:'Gotham-Bold';font-size:12pt;padding-top:7px;padding-left:8px;text-transform: uppercase;line-height:1.24rem;">
                {{previewValue.title}}
            </h3>
        </div>
        <!-- Description -->
        <div style="position:absolute;top:152px;left:37px;max-height:140px;width:189px;overflow:hidden">
            <div style="position:relative;margin-top:0px;margin-left:7px;margin-right:7px;">
                <p style="font-family:'Gotham-Book';font-weight:300;font-size:10pt;line-height:1rem;">
                {{previewValue.shelf_description}}
                   
                </p>
            </div>
        </div>
        <div style="position:absolute;bottom:30px;left:24px;height:82px;width:79px;">
            <div style="position:absolute;top:9px;left:6px;height:52px;width:52px;background-color:rgba(0,0,0,0.2)">
                     
               
            </div>
        </div>
        
        <!-- Price -->
        <div style="position:absolute;bottom: 48px;right:25px;height:41px;width:60px;">
            <h3 style="color:black;font-size:24pt;padding-left:8px;padding-top:1px;font-family:'Gotham-Medium';font-weight:300;margin:0 auto;">
                
                ${{previewValue.price.split('.')[0]}}
            </h3>
        </div>
       
    </div>
</template>
<script>
export default {
    name: 'preview-market',
    props: {
        previewValue: {},
    },
    created() {
        console.log('created beer');
    },
    mounted() {
        console.log('mounted beer');
        console.log(this.previewValue.style.split('-'));
    },
    methods: {
        returnOther() {
            if((this.previewValue.style.toLowerCase()).includes('belgian ale') == false && 
                (this.previewValue.style.toLowerCase()).includes('trappist') == false &&
                 (this.previewValue.style.toLowerCase()).includes('golden ale') == false &&
                (this.previewValue.style.toLowerCase()).includes('dark ale') == false &&
                (this.previewValue.style.toLowerCase()).includes('pale ale') == false && 
                (this.previewValue.style.toLowerCase()).includes('saison') == false &&
                (this.previewValue.style.toLowerCase()).includes('lager') == false &&
                (this.previewValue.style.toLowerCase()).includes('cider') == false &&
                (this.previewValue.style.toLowerCase()).includes('stout') == false &&
                (this.previewValue.style.toLowerCase()).includes('farmhouse') == false &&
                this.previewValue.style.includes('Gluten') == false &&
                (this.previewValue.style.toLowerCase()).includes('hard seltzer') == false &&
                (this.previewValue.style.toLowerCase()).includes('mead') == false &&
                (this.previewValue.style.toLowerCase()).includes('ipa') == false &&
                (this.previewValue.style.toLowerCase()).includes('pilsner') == false &&
                (this.previewValue.style.toLowerCase()).includes('porter') == false &&
                (this.previewValue.style.toLowerCase()).includes('sour') == false &&
                (this.previewValue.style.toLowerCase()).includes('wheat') == false) {
                    console.log('testing value: ');
                    return true;
                } else {
                    console.log('no value');
                    return false;
                }
        },
        returnRed() {
            if((this.previewValue.grape_logo != 0)) {
                if((this.previewValue.grape_logo.split('/')[5]).split('_').includes('red')) {
                    return true;
                }
                
            } else {
                return false;
            }
        },
        returnWhite() {
            if((this.previewValue.grape_logo != 0)) {
                if((this.previewValue.grape_logo.split('/')[5]).split('_').includes('white')) {
                    return true;
                }
                
            } else {
                return false;
            }
        },
        returnRose() {
            if((this.previewValue.grape_logo != 0)) {
                if((this.previewValue.grape_logo.split('/')[5]).split('_').includes('rose.jpg')) {
                    return true;
                }
                
            } else {
                return false;
            }
        },
        returnOrange() {
            if((this.previewValue.grape_logo) == 0) {
                return true;
                
            } else {
                return false;
            }
        },
        splitTitle(title) {
            var st = title.split('-');
            return st[0];
        },
        splitSecondTitle(title) {
            var st = title.split(',');
            return st[0];
        },
    },
    
}
</script>
<style>

.beerGoldenAle { background-image: url('https://bfapi.coolvines.com/img/beer/lightale.PNG');  }
.beerDarkAle { background-image: url('https://bfapi.coolvines.com/img/beer/darkale.PNG');  }
.beerBelgian { background-image: url('https://bfapi.coolvines.com/img/beer/belgian.PNG');  }   
.beerTripel { background-image: url('https://bfapi.coolvines.com/img/beer/belgian.PNG');  }
.beerLager { background-image: url('https://bfapi.coolvines.com/img/beer/lager.PNG');  }
.beerCider { background-image: url('https://bfapi.coolvines.com/img/beer/cider.PNG');  }
.beerFarmhouse { background-image: url('https://bfapi.coolvines.com/img/beer/farmhouse.PNG');  }
.beerGluten { background-image: url('https://bfapi.coolvines.com/img/beer/glutenfree.PNG');  }
.beerHardSeltzer { background-image: url('https://bfapi.coolvines.com/img/beer/hardseltzer.PNG');  }
.beerMead { background-image: url('https://bfapi.coolvines.com/img/beer/mead.PNG');  }
.beerIPA { background-image: url('https://bfapi.coolvines.com/img/beer/ipa.PNG');  }
.beerPaleAle { background-image: url('https://bfapi.coolvines.com/img/beer/paleale.PNG');  }
.beerPilsner { background-image: url('https://bfapi.coolvines.com/img/beer/pilsner.PNG');  }
.beerPorter { background-image: url('https://bfapi.coolvines.com/img/beer/porter.PNG');  }
.beerSour { background-image: url('https://bfapi.coolvines.com/img/beer/sour.PNG');  }
.beerStout { background-image: url('https://bfapi.coolvines.com/img/beer/stout.PNG');  }
.beerWheat { background-image: url('https://bfapi.coolvines.com/img/beer/wheat.PNG');  }
.beerOther { background-image: url('https://bfapi.coolvines.com/img/beer/otherbeer.PNG');  }
</style>