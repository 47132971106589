import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('access_token') || null,
    token2: localStorage.getItem('access_token2') || null,
    tokenLocal: localStorage.getItem('access_token_local') || null,
    shelf: [],
    posCategory: '',
    posItem: '',
    page: 1,
    query: '',
    items: [],
    displayResults: [],
  },
  getters: {
    loggedIn(state) {
      return state.token !== null
    }
  },
  mutations: {
    retrieveToken(state, token) {
      state.token = token;
      
    },
    destroyToken(state) {
      state.token = null
    },
    addToShelf(state, sitem) {
      let itemInShelfIndex = state.shelf.findIndex(item => item.id === sitem.id);
      if(itemInShelfIndex !== -1) {
        state.shelf[itemInShelfIndex] = sitem;
        return;
      }
      state.shelf.push(sitem);
    },
    removeFromShelf(state, index) {
      let shelfIndex = state.shelf.findIndex(item => item.id === index);
      console.log(state.shelf[shelfIndex]);
      state.shelf.splice(shelfIndex, 1);
      
  },
    
    
  },
  actions: {
    
    retrieveName(context) {
      return new Promise((resolve, reject) => {
        axios.get('/api/user')
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
      })
    },
    register(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('register', {
          name: data.name,
          email: data.name,
          password: data.password,
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
      })
    },
    destroyToken(context) {
      if(context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios.post('/logout')
            .then(response => {
              localStorage.removeItem('access_token');
              localStorage.removeItem('access_token2');
              localStorage.removeItem('access_token_local');
              context.commit('destroyToken')
              resolve(response)
              // console.log(response);
            })
            .catch(errpr => {
              localStorage.removeItem('access_token')
              context.commit('destroyToken')
              reject(error)
            })
        })
      }
    },
    retrieveToken(context, credentials) {
      return new Promise((resolve, reject) => {
        axios.get('/sanctum/csrf-cookie').then(response => {
          axios.post('/login', {
            
              email: credentials.email,
              password: credentials.password,
          
          }).then(response2 => {
            axios.get('/api/user').then(response4 => {
              const token = response4.data.email;
              const isadminToken = response4.data.isadmin;
              const localToken = response4.data.local;
              localStorage.setItem('access_token', token);
              localStorage.setItem('access_token2', isadminToken);
              localStorage.setItem('access_token_local', localToken);
              
              context.commit('retrieveToken', token);
              resolve(response);
              console.log(response4.data.email);
              console.log(response4.data);
            });
          }).catch(error => {
            console.log(error)
            reject(error)
          });
        });
      })
    },
    

  },
  modules: {
  }
})
