<template>
<div
        :class="{
                   
                    'beerBelgian2' : (previewValue.style.toLowerCase()).includes('belgian ale') || (previewValue.style.toLowerCase()).includes('trappist'),
                    'beerGoldenAle2': (previewValue.style.toLowerCase()).includes('golden ale'),
                    'beerDarkAle2': (previewValue.style.toLowerCase()).includes('dark ale'),
                    'beerPaleAle2': (previewValue.style.toLowerCase()).includes('pale ale') || (previewValue.style.toLowerCase()).includes('saison'),
                    'beerLager2': (previewValue.style.toLowerCase()).includes('lager'),
                    'beerCider2': (previewValue.style.toLowerCase()).includes('cider'),
                    'beerStout2': (previewValue.style.toLowerCase()).includes('stout'),
                    
                    'beerFarmhouse2': (previewValue.style.toLowerCase()).includes('farmhouse'),
                    'beerGluten2': previewValue.style.includes('Gluten'),
                    'beerHardSeltzer2': (previewValue.style.toLowerCase()).includes('hard seltzer'),
                    'beerMead2': (previewValue.style.toLowerCase()).includes('mead'),
                    'beerIPA2': (previewValue.style.toLowerCase()).includes('ipa'),
                    'beerPilsner2': (previewValue.style.toLowerCase()).includes('pilsner'),
                    'beerPorter2': (previewValue.style.toLowerCase()).includes('porter'),
                    'beerSour2': (previewValue.style.toLowerCase()).includes('sour'),
                    'beerWheat2': (previewValue.style.toLowerCase()).includes('wheat'),
                    'beerOther2': returnOther(),
                }" 
        style="position:relative;top:0px;left:0px;right:0px;width:402px;height:115px;;background-size:cover;border: 1px solid rgba(0,0,0,0.2)">
        <!-- Title -->
        <div style="position:absolute;top:0px;left:48px;height:95px;width:294px;">
            <h3 style="color:black;font-family:'Gotham-Bold';font-size:14pt;padding-top:7px;padding-left:6px;text-transform: uppercase;line-height:1.32rem;letter-spacing:0.01rem;">
                {{previewValue.title}}
            </h3>
        </div>
        <!-- Farm Practice -->
        <div v-if="previewValue.farmpractice_limited" style="position:absolute;top:0px;right:0px;height:60px;width:62px;">
            <div style='position:absolute;top:12px;left:12px;height:37px;width:38px;border-radius:24px;margin: 1px auto;padding:2px;background-image:url(http://bfapi.coolvines.com/img/farm/limited.png);background-size:contain;background-position:center center;background-repeat: no-repeat;'>
            </div>
                  
            
            
        </div>
        <!-- Bottle Size -->
        <div style="position:absolute;top:90px;left:164px;height:27px;width:175px;">
            <h3 style="color:black;font-size:11pt;padding-left:10px;padding-top:6px;font-family:'Gotham-Light';font-weight:200;margin:0 auto;">
               {{previewValue.size}}
            </h3>          
        </div>
        <!-- Price -->
        <div style="position:absolute;bottom:0px;right:0px;height:34px;width:75px;overflow:hidden;">
            <h3 style="color:black;font-size:24pt;padding-left:8px;padding-top:1px;font-family:'Gotham-Medium';font-weight:300;margin:0 auto;">
                
               ${{previewValue.price.split('.')[0]}}
            </h3>
        </div>
        <!-- Farming Practice -->
      
        
    </div>
</template>
<script>
export default {
    name: 'preview-beer2',
    props: {
        previewValue: {},
    },
    created() {
        console.log('created beer');
    },
    mounted() {
        console.log('mounted beer');
        console.log(this.previewValue.style.split('-'));
    },
    methods: {
        returnOther() {
            if((this.previewValue.style.toLowerCase()).includes('belgian ale') == false && 
                (this.previewValue.style.toLowerCase()).includes('trappist') == false &&
                 (this.previewValue.style.toLowerCase()).includes('golden ale') == false &&
                (this.previewValue.style.toLowerCase()).includes('dark ale') == false &&
                (this.previewValue.style.toLowerCase()).includes('pale ale') == false && 
                (this.previewValue.style.toLowerCase()).includes('lager') == false && 
                (this.previewValue.style.toLowerCase()).includes('saison') == false &&
                (this.previewValue.style.toLowerCase()).includes('cider') == false &&
                (this.previewValue.style.toLowerCase()).includes('stout') == false &&
                (this.previewValue.style.toLowerCase()).includes('farmhouse') == false &&
                this.previewValue.style.includes('Gluten') == false &&
                (this.previewValue.style.toLowerCase()).includes('hard seltzer') == false &&
                (this.previewValue.style.toLowerCase()).includes('mead') == false &&
                (this.previewValue.style.toLowerCase()).includes('ipa') == false &&
                (this.previewValue.style.toLowerCase()).includes('pilsner') == false &&
                (this.previewValue.style.toLowerCase()).includes('porter') == false &&
                (this.previewValue.style.toLowerCase()).includes('sour') == false &&
                (this.previewValue.style.toLowerCase()).includes('wheat') == false) {
                    console.log('testing value: ' + this.previewValue.style);
                    return true;
                } else {
                    console.log('no value');
                    return false;
                }
        },
        returnRed() {
            if((this.previewValue.grape_logo != 0)) {
                if((this.previewValue.grape_logo.split('/')[5]).split('_').includes('red')) {
                    return true;
                }
                
            } else {
                return false;
            }
        },
        returnWhite() {
            if((this.previewValue.grape_logo != 0)) {
                if((this.previewValue.grape_logo.split('/')[5]).split('_').includes('white')) {
                    return true;
                }
                
            } else {
                return false;
            }
        },
        returnRose() {
            if((this.previewValue.grape_logo != 0)) {
                if((this.previewValue.grape_logo.split('/')[5]).split('_').includes('rose.jpg')) {
                    return true;
                }
                
            } else {
                return false;
            }
        },
        returnOrange() {
            if((this.previewValue.grape_logo) == 0) {
                return true;
                
            } else {
                return false;
            }
        },
        splitTitle(title) {
            var st = title.split('-');
            return st[0];
        },
        splitSecondTitle(title) {
            var st = title.split(',');
            return st[0];
        },
    },
    
}
</script>
<style>

.beerGoldenAle2 { background-image: url('http://bfapi.coolvines.com/img/beer2/lightale.PNG');  }
.beerDarkAle2 { background-image: url('http://bfapi.coolvines.com/img/beer2/darkale.PNG');  }
.beerBelgian2 { background-image: url('http://bfapi.coolvines.com/img/beer2/belgian.PNG');  }   
.beerTripel2 { background-image: url('http://bfapi.coolvines.com/img/beer2/belgian.PNG');  }
.beerCider2 { background-image: url('http://bfapi.coolvines.com/img/beer2/cider.PNG');  }
.beerLager2 { background-image: url('http://bfapi.coolvines.com/img/beer2/lager.PNG');  }
.beerFarmhouse2 { background-image: url('http://bfapi.coolvines.com/img/beer2/farmhouse.PNG');  }
.beerGluten2 { background-image: url('http://bfapi.coolvines.com/img/beer2/glutenfree.PNG');  }
.beerHardSeltzer2 { background-image: url('http://bfapi.coolvines.com/img/beer2/hardseltzer.PNG');  }
.beerMead2 { background-image: url('http://bfapi.coolvines.com/img/beer2/mead.PNG');  }
.beerIPA2 { background-image: url('http://bfapi.coolvines.com/img/beer2/ipa.PNG');  }
.beerPaleAle2 { background-image: url('http://bfapi.coolvines.com/img/beer2/paleale.PNG');  }
.beerPilsner2 { background-image: url('http://bfapi.coolvines.com/img/beer2/pilsner.PNG');  }
.beerPorter2 { background-image: url('http://bfapi.coolvines.com/img/beer2/porter.PNG');  }
.beerSour2 { background-image: url('http://bfapi.coolvines.com/img/beer2/sour.PNG');  }
.beerStout2 { background-image: url('http://bfapi.coolvines.com/img/beer2/stout.PNG');  }
.beerWheat2 { background-image: url('http://bfapi.coolvines.com/img/beer2/wheat.PNG');  }
.beerOther2 { background-image: url('http://bfapi.coolvines.com/img/beer2/other.PNG');  }
</style>