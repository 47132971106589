<template>
    <div class="col-12">
                            <router-link 
                            :to="{
                              name: 'test', 
                              params: {slug: item.slug},
                              }"  
                              class="fixRouterLink"
                            > 
                        <div aria-labelledby="demosMegaMenu" style="max-width: 450px;background-color: white;border-top: 1px solid #e6e6e6;border-left: 1px solid #e6e6e6;border-right: 1px solid #e6e6e6;" class="w-100 hs-mega-menu-desktop-lg hs-position-right animated p-2">
                          <div class="row no-gutters py-1">
                            <div class="col-8" style="display: flex;justify-content: center;align-items: center;">
                              <div class="row">
                                <div class="col-2"></div>
                                <div class="col-10 text-center" style="white-space:initial;margin-top: ;padding-bottom: 4px;margin-top: -4px;">
                                  <span style="font-size: 1.1rem;margin-left: ;color: rgb(83, 83, 83);margin-top: ;padding-top: -10px;">
                                    {{ item.title }}</span>
                                </div>
                                <div class="col-2"></div>
                                <div class="col-4 text-center topScore" v-html="item.topscore">

                                </div>
                                <div class="col-6 text-left">
                                  <span style="color: #7e7e7e;" v-html="item.style"></span>
                                </div>
                              </div>   
                                      
                            </div>
      
                            <div class="col-4">
                              <a href="#" class="py-1">
                                <div class="position-relative" style="display: flex;justify-content: center;align-items: center;">
                                  <img v-if="item.img_src" :src="item.img_src" style="border-radius: 0px;height: 80px;width: auto;" class="img-fluid">
                                  <div v-else style="height:80px;width:auto;object-fit:contain;display:flex;align-items:center;justify-content:center;" class="rounded mb-4">
                                    <i class="uil uil-capture" style="font-size:4rem;color:rgba(0,0,0,0.1)"></i>
                                  </div>
                                </div>
                                
                              </a>
                            </div> <!-- end col 4-->
                          </div>
                        </div></router-link>
                        </div>
</template>
<script>

export default {
    name: 'cart-item',
    props: {
        index: {},
        item: {},
    },
    methods: {
        
    }
}
</script>
<style scoped>
.topScore >>> img {
  padding-left: 1.4px;
  padding-right: 1.4px;
}
</style>
